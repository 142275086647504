import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function SuspendAccountConfirm({ hide, ...props }) {
  const { http } = global.services;
  const [state, setState] = useState({
    loading: false,
  });

  const handleSubmit = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const action = props.status === "active" ? "suspended" : "active";
      const req = await http.patch(`account/${props.id}/status/${action}`);
      if (req.data.success) {
        toast.success(req.data.message);
        hide();
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end space-x-2">
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          buttonType="default"
          onClick={hide}
        />
        <Button
          key="suspend-btn"
          data-cc="suspend-btn"
          buttonName={`YES, ${
            props.status === "active" ? "Suspend" : "Activate"
          } IT`}
          buttonClass="bg-lightRed rounded-full py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          buttonType="error"
          onClick={handleSubmit}
          disabled={state.loading}
          icon={
            state.loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="590px"
      contentClassName="rounded-none"
      wrapperClassName="overflow-visible"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            {props.status === "active" ? "Suspend" : "Activate"} Account
          </h3>
          <div className="mt-4 relative">
            <p className="text-lg opacity-70">
              {`Are you sure you want to ${
                props.status === "active" ? "suspend" : "activate"
              } this account ${props.full_name || "--"}
              (${props.email || "--"})?`}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
