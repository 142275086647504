import React from "react";
import Table from "components/table/table";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import CloseIconSVG from "assets/svg/close-icon";
import _ from "lodash";
import moment from "moment";

const color = {
  scheduled: "text-status-scheduled",
  sent: "text-status-sent",
  cancelled: "text-status-cancelled",
};

const renderType = (record) => (
  <span className="capitalize">{record.type}</span>
);
const renderTitle = (record) => (
  <span className="capitalize">{record.title}</span>
);
const renderMessage = (record) => (
  <p className="truncate w-40">{record.message}</p>
);
const renderSchedule = (record) => (
  <p>
    {record?.schedule
      ? moment(record.schedule).format("DD MMM YYYY, hh:mm A")
      : "--"}
  </p>
);

const renderCreatedAt = (record) => (
  <p>
    {record?.created_at
      ? moment(record.created_at).format("DD MMM YYYY, hh:mm A")
      : "--"}
  </p>
);

const renderStatus = (record) => (
  <span className={`capitalize font-bold ${color[record.status]}`}>
    {record.status}
  </span>
);

const renderActions = (handleAction) => (record) => (
  <div className="flex flex-row items-center">
    <ActionIconBtn
      className="action-btn-secondary"
      icon={<EditPencilIconSVG className="m-auto" />}
      onClick={() => handleAction("edit", record)}
    />
    <ActionIconBtn
      className="action-btn-secondary"
      icon={<CloseIconSVG className="m-auto" />}
      onClick={() => handleAction("delete", record)}
    />
  </div>
);

const NotificationTable = (props) => {
  const { loading, notifications, setPage, handleSort, handleAction } = props;

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: renderType,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: renderTitle,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: renderMessage,
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      hideSorting: true,
      key: "schedule",
      render: renderSchedule,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: renderCreatedAt,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      hideSorting: true,
      nowrap: true,
      render: renderActions(handleAction),
    },
  ];

  //pages
  const pageCount =
    Math.ceil(
      notifications.metadata?.total / notifications.metadata?.per_page
    ) || 0;
  const pages = _.range(1, pageCount + 1);

  return (
    <div className="mt-5">
      <Table
        columns={columns}
        dataSource={notifications.data}
        loading={loading}
        pages={pages}
        onHandleChangeSorting={(column) => handleSort(column)}
        onHandleChangePage={(page) => setPage(page)}
        perPage={10}
        showingItems={`${notifications.metadata?.from || 0}-${
          notifications.metadata?.to || 0
        } of ${notifications.metadata?.total || 0}`}
        currentPage={notifications.metadata?.current_page}
      />
    </div>
  );
};

export default NotificationTable;
