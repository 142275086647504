import SearchIconSVG from "assets/svg/search-icon";
import Button from "components/button/button";
import Card from "components/card/card";
import CustomPlanDrawer from "components/custom-plans/drawer";
import CustomPlanPanel from "components/custom-plans/panel";
import PlansTable from "components/custom-plans/plans-table";
import Backdrop from "components/global/backdrop";
import { TabSelector } from "components/global/tabselector";
import Input from "components/input/input";
import SearchValueCloseable from "components/search-value-closeable/search-value-closeable";
import { Context } from "context/context";
import Layout from "layouts/layout";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";

const tabs = [
  {
    tab: "all",
    name: "All",
    ListComponent: PlansTable,
  },
  {
    tab: "scheduled",
    name: "Scheduled",
    ListComponent: PlansTable,
  },
  {
    tab: "sent",
    name: "Sent",
    ListComponent: PlansTable,
  },
];

const INTITIALS = {
  plan_name: [""],
  currency: [""],
  interval: [""],
  amount: [""],
  email: [""],
};

export default function CustomPlans() {
  const { http } = global.services;
  const { active_tab } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [onLoadPanel, setLoadPanel] = useState(false);
  const [search, setSearch] = useState({
    initialKeyword: "",
    keyword: "",
  });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedTab, setSelectedTab] = useTabs(["all", "scheduled", "sent"]);
  const { showPanel, setShowPanel } = useContext(Context);
  const [errors, setErrors] = useState(INTITIALS);

  const handleBackdropClick = (event) => {
    if (event.currentTarget === event.target) {
      setShowPanel(false);
      setSelectedPlan(null);
      setErrors(INTITIALS);
    }
  };

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setSelectedTab(tab);
      history.push(`/custom-plans/${tab}`);
    }
  };

  const onHandleShowPanel = (data) => {
    setSelectedPlan(data);
    setShowPanel(true);
  };

  const handleSearch = () => {
    setSearch((prev) => ({
      ...prev,
      keyword: search.initialKeyword,
    }));
  };

  const handleSubmitPlan = async (data) => {
    setLoadPanel(true);
    const params = {
      ...data,
      currency: data.currency.value,
      interval: data.interval.value,
      amount: data.amount * 100,
    };

    if (data.plan_name) {
      if (_.size(_.trim(data.plan_name)) > 100) {
        return toast.error("Plan Name should not greater than 100 characters.");
      }
    }

    if (selectedPlan) {
      try {
        const req = await http.put(
          `custom-subscriptions/${selectedPlan.id}`,
          params
        );
        toast.success(req.data.message);
        setShowPanel(false);
        setSelectedPlan(null);
      } catch (error) {
        toast.error(
          error.status === 401
            ? "Invalid Credentials"
            : error?.data?.message || "It appears that something went wrong"
        );
      } finally {
        setLoadPanel(false);
      }
    } else {
      try {
        const req = await http.post(`custom-subscriptions`, params);
        toast.success(req.data.message);
        setShowPanel(false);
      } catch (error) {
        console.log("error => ", error);
        if (error?.status === 422) {
          if (error?.data) {
            setErrors((prev) => ({ ...prev, ...error.data }));
          }
        }
        toast.error(
          error.status === 401
            ? "Invalid Credentials"
            : error?.data?.message || "It appears that something went wrong"
        );
      } finally {
        setLoadPanel(false);
      }
    }
  };

  useEffect(() => {
    if (!active_tab) history.push(`/custom-plans/all`);
    else setSelectedTab(active_tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_tab]);

  return (
    <Layout title="Custom Plans" routeName="custom-plans">
      <div className="main-page-wrapper">
        {/* header */}
        <div className="grid items-center md:grid-cols-2">
          <nav className="flex my-3">
            <div className="relative">
              {tabs.map((item, index) => {
                return (
                  <TabSelector
                    key={index}
                    isActive={selectedTab === item.tab}
                    onClick={() => toggle(item.tab)}
                  >
                    {item.name}
                  </TabSelector>
                );
              })}
            </div>
          </nav>

          <div className="w-full space-y-2 1md:flex 1md:space-x-2 1md:space-y-0 1md:items-center 1md:justify-end">
            <Input
              placeholder="Search"
              noLabelPaddingY="0.8rem"
              value={search.initialKeyword}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  initialKeyword: e.target.value,
                }))
              }
              onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
              inputIcon={
                <div className="cursor-pointer" onClick={handleSearch}>
                  <SearchIconSVG />
                </div>
              }
            />
            <Button
              buttonName="ASSIGN PLAN"
              buttonClass="relative bg-primary py-3 px-8 "
              buttonTextClass="text-white text-sm"
              onClick={() => setShowPanel(true)}
            />
          </div>
        </div>
        {/* content */}
        <div className="mt-8">
          {!_.isEmpty(search.keyword) && (
            <div className="w-full mb-4 flex items-center flex-wrap">
              <div className="mr-4">
                <p>Searching for:</p>
              </div>
              <SearchValueCloseable
                value={search.keyword}
                onClick={() => {
                  setPage(1);
                  setSearch({ ...search, keyword: "", initialKeyword: "" });
                }}
              />
            </div>
          )}

          {tabs.map((item, index) => {
            const { ListComponent } = item;
            return (
              <TabPanel key={index} hidden={selectedTab !== item.tab}>
                <Card padding="p-0">
                  <ListComponent
                    showPanel={(data) => onHandleShowPanel(data)}
                    onChangePage={(page) => setPage(page)}
                    drawerClose={!showPanel}
                    page={page}
                    search={search.keyword}
                    selectedTab={selectedTab}
                  />
                </Card>
              </TabPanel>
            );
          })}
        </div>
      </div>
      {showPanel ? (
        <Backdrop
          className="flex justify-end"
          onMouseDown={handleBackdropClick}
        >
          <CustomPlanPanel className="bg-white z-60">
            <CustomPlanDrawer
              hide={() => {
                setShowPanel(false);
                setSelectedPlan(null);
                setErrors(INTITIALS);
              }}
              onLoadPanel={onLoadPanel}
              onSubmit={handleSubmitPlan}
              selectedPlan={selectedPlan}
              errors={errors}
              setErrors={setErrors}
            />
          </CustomPlanPanel>
        </Backdrop>
      ) : null}
    </Layout>
  );
}
