import React from "react";

const color = {
  default: {
    active:
      "border-secondary text-black focus:outline-none focus:text-black focus:border-secondary",
    inactive:
      "border-transparent text-tabTextColor hover:text-black hover:border-secondary focus:text-black focus:border-secondary",
  },
  black: {
    active:
      "border-black text-black focus:outline-none focus:text-black focus:border-black",
    inactive:
      "border-transparent text-tabTextColor hover:text-black hover:border-black focus:text-black focus:border-black",
  },
};

export const TabSelector = ({
  isActive,
  colorType = "default",
  children,
  gap = "mr-8",
  paddingY = "py-4",
  paddingX = "px-2",
  onClick = () => {},
}) => (
  <button
    type="button"
    className={`${gap} group inline-flex items-center ${paddingX} border-b-2 ${paddingY} text-sm leading-5 cursor-pointer whitespace-nowrap ${
      isActive ? color[colorType].active : color[colorType].inactive
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);
