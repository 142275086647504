import { csx } from "utils/utils";
import logo from "assets/images/logo-white.png";

const NotificationItemDefault = ({ notification }) => {
  const messageClasses = csx([
    "text-mutedGray",
    notification.message.length > 80 ? "truncate" : "break-words",
  ]);
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <div className="bg-secondary w-6 h-6 flex items-center justify-center rounded-full">
          <img
            className="w-4 h-4 object-center"
            src={logo}
            alt="ConversionCow Logo Notification"
          />
        </div>
        <span className="uppercase ml-2 text-sm">ConversionCow</span>
        <span className="mx-1">&bull;</span>
        <span className="text-sm uppercase">{notification.relative_time}</span>
      </div>
      <div>
        <h3 className="text-base font-medium mt-2 mb-px">
          {notification.title}
        </h3>
        <p title={notification.message} className={messageClasses}>
          {notification.message}
        </p>
      </div>
    </div>
  );
};

export default NotificationItemDefault;
