import CalendarColordIcon from "assets/svg/calendar-icon-colored";
import React from "react";
import DatePickerComponent from "react-datepicker";
import moment from "moment";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";

export default function DatePicker({
  selected = null,
  onChange = () => {},
  label = "",
  iconLeft = true,
  labelClass = "text-base font-larsseit",
  icon = "default",
  ...props // make sure this pops here is for input only
}) {
  return (
    <div className="relative form-input-container overflow-visible">
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
        </label>
      )}
      {iconLeft && icon === "default" && (
        <CalendarColordIcon className="absolute top-11.5 right-4" />
      )}
      {iconLeft && icon === "clock" && (
        <div className="flex flex-row items-center justify-between gap-2 z-0 pointer-events-none absolute top-11.5 right-4 z-0">
          <span
            className={`${
              moment(selected, "hh:mm A").format("A") === "AM"
                ? "font-bold text-secondary"
                : ""
            }`}
          >
            AM
          </span>
          <span
            className={`${
              moment(selected, "hh:mm A").format("A") === "PM"
                ? "font-bold text-secondary"
                : ""
            }`}
          >
            PM
          </span>
        </div>
      )}

      <DatePickerComponent
        className={label && "mt-2"}
        selected={selected}
        onChange={onChange}
        {...props}
        nextMonthButtonLabel={
          <GoChevronRight className="w-5 h-5 text-gray-600" />
        }
        previousMonthButtonLabel={
          <GoChevronLeft className="w-5 h-5 text-gray-600" />
        }
      />
    </div>
  );
}
