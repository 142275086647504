import { useDropzone } from "react-dropzone";
import CloudDropboxSVG from "assets/svg/cloud-dropbox-svg";
import { useCallback, useState, Fragment } from "react";

const acceptedFiles = ["png", "jpg", "jpeg", "gif"];
const getExtension = (filename = "") =>
  filename ? filename.split(".").at(-1) : "";
const FILESIZE_LIMIT = 2097152; // 2mb

const Dropbox = ({ onDrop: userOnDropHandler, file: userFile }) => {
  const [file, setFile] = useState(userFile);

  const onDrop = useCallback(
    (files) => {
      const firstAcceptedFile = files.find((current) => {
        const fileExtension = getExtension(current.name);
        return (
          acceptedFiles.includes(fileExtension) &&
          current.size <= FILESIZE_LIMIT
        );
      });
      if (firstAcceptedFile) {
        setFile(firstAcceptedFile);
        userOnDropHandler(firstAcceptedFile);
      }
    },
    [userOnDropHandler, setFile]
  );

  const handleRemoveClick = () => {
    setFile(null);
    userOnDropHandler(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
  });
  const textColor = isDragActive ? "text-status-sent" : "text-darkGray";
  const className = [
    "notification-dropbox mt-5 flex flex-col py-8 text-xs cursor-pointer",
    textColor,
    isDragActive ? "border-status-sent bg-status-sent bg-opacity-25" : "",
  ].join(" ");
  return (
    <div className={className} {...getRootProps()}>
      {file ? (
        <div className="flex items-center">
          <p>{file.name}</p>{" "}
          <span
            className="text-lg cursor-pointer ml-2"
            title="Remove"
            onClick={handleRemoveClick}
          >
            &times;
          </span>
        </div>
      ) : (
        <Fragment>
          <CloudDropboxSVG className={`fill-current ${textColor}`} />
          <p>Drop images here</p>
          <p>PNG, JPG, GIF, not more than 2MB or 2000 x2000 px</p>
        </Fragment>
      )}
      <input
        type="file"
        hidden
        {...getInputProps({
          accept: "image/png, image/jpeg, image/jpg, image/gif",
        })}
      />
    </div>
  );
};

export default Dropbox;
