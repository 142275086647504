/** Resource: https://jakzaizzat.com/how-to-build-custom-checkbox-in-tailwindcss/ */

const Checkbox = ({
  name,
  checked,
  label = "",
  onChange = null,
  value = true,
  disabled = false,
  containerStyle = {},
}) => {
  return (
    <div className="flex items-center relative" style={containerStyle}>
      <input
        type="checkbox"
        id={`checkbox-${name}`}
        value={value}
        checked={checked}
        className="h-5 w-5 mr-2 rounded"
        onChange={(value) => (onChange ? onChange(value) : null)}
        disabled={disabled}
      />
      <label
        htmlFor={`checkbox-${name}`}
        className="select-none text-lg focus-within:font-medium cursor-pointer"
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
