import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function NotificationModal({ hide, ...props }) {
  const { http } = global.services;
  const [state] = useState({
    loading: false,
  });

  console.log(props.action);

  const handleSubmit = () => {
    if (props.action === "delete") handleDelete();
    if (props.action === "edit") hide("update");
  };

  const handleDelete = async () => {
    const { data: response } = await http.delete(
      `notifications/${props.record.id}`
    );
    return hide(response);
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end w-8/12 space-x-2">
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          buttonType="default"
          onClick={hide}
        />
        <Button
          key="delete-btn"
          buttonName="YES"
          buttonClass={`${
            props.action === "edit" ? "bg-primary" : "bg-lightRed"
          } rounded-full py-3 px-9`}
          buttonTextClass="text-white text-sm font-bold"
          buttonType={props.action === "edit" ? "primary" : "error"}
          onClick={handleSubmit}
          buttonWidth="55%"
          disabled={state.loading}
          icon={
            state.loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="590px"
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9 capitalize" id="modal-title">
            {props.action} Confirmation
          </h3>
          <div className="mt-4 relative">
            <p className="text-lg opacity-70">
              {props.action === "delete" &&
                "Are you sure you want to delete this notification?"}
              {props.action === "edit" &&
                "This notification has already been sent to the client, are you sure you still want to update?"}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
