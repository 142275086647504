import React from "react";
import LoadingIndicator from "assets/svg/loading-indicator";
import "./styles.css";

const Overview = ({ items, loading = false }) => {
  return (
    <div className="analytics-overview">
      {items.map((item, index) => {
        return (
          <div key={index} className="overview-card-item">
            <div>
              <p className="text-sm">{item.label}</p>
              {loading ? (
                <LoadingIndicator className="w-6 h-6 mt-3 mb-2 text-secondary" />
              ) : (
                <p className="text-3xl">{item.value}</p>
              )}
            </div>
            <div>{item.icon}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Overview;
