import React, { useState } from "react";
import { Close } from "assets/svg/sidebar/icons";
import toast from "react-hot-toast";
import RoundedButton from "components/global/button";
import Input from "components/input/input";
import LoadingIndicator from "assets/svg/loading-indicator";
import { removeTrailingSlash } from "components/helpers/stringConverter";

export default function BlacklistDrawer({ hide = () => {} }) {
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ url: "", description: "" });

  const AddBlacklist = async () => {
    try {
      const cleanUrl = details;
      cleanUrl.url = removeTrailingSlash(cleanUrl.url);
      const req = await http.post(`blacklist`, details);
      const data = req.data;
      if (data.success) {
        hide(data.message);
        setDetails({ url: "", description: "" });
      }
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* Ttitle */}
      <div className="grid grid-cols-2 mb-10">
        <div>
          <h1 className="text-2xl">URL Blacklist</h1>
        </div>
        {/*  */}
        <div className="my-auto">
          <button className="float-right" onClick={() => hide("")}>
            <Close className="w-5 h-5" fill="#989898" strokeWidth={1} />
          </button>
        </div>
      </div>
      {/* content */}
      <div>
        <Input
          label="Site URL"
          placeholder="https://example.com"
          value={details.url}
          onChange={(e) => {
            setDetails((prev) => ({ ...prev, url: e.target.value }));
          }}
          inputClass="mb-5"
        />
        <Input
          label="Description"
          placeholder="Drugs, Gambling, Sex, etc..."
          onChange={(e) => {
            setDetails((prev) => ({ ...prev, description: e.target.value }));
          }}
        />
      </div>
      {/* footer */}
      <div className="mt-auto w-full flex items-center justify-between ">
        <RoundedButton
          className="w-5/12 bg-notification-button-gray text-xs text-black uppercase"
          onClick={() => hide()}
          buttonType="default"
        >
          Cancel
        </RoundedButton>
        <RoundedButton
          className="w-6/12 bg-primary text-xs text-white uppercase relative"
          onClick={AddBlacklist}
          disabled={loading}
          buttonType="primary"
          icon={
            loading && (
              <LoadingIndicator className="w-5 h-5 absolute right-4 top-2.5" />
            )
          }
        >
          Add Blacklist
        </RoundedButton>
      </div>
    </div>
  );
}
