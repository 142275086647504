import React from "react";
import { csx } from "utils/utils";

const Card = ({ children, className, ...props }) => {
  const classes = csx([
    "w-full h-full min-h-1px bg-white rounded-md",
    className,
  ]);
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default Card;
