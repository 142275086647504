const EditPencilIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59353 14.0188C3.41255 14.0185 3.24002 13.9422 3.11804 13.8085C2.99382 13.6759 2.93209 13.4965 2.94837 13.3156L3.10643 11.5775L10.4084 4.27814L12.6903 6.55943L5.3903 13.8581L3.65224 14.0162C3.63224 14.0181 3.61224 14.0188 3.59353 14.0188ZM13.1458 6.1033L10.8645 3.82201L12.2329 2.45362C12.3539 2.33248 12.5181 2.2644 12.6893 2.2644C12.8606 2.2644 13.0248 2.33248 13.1458 2.45362L14.5142 3.82201C14.6353 3.94302 14.7034 4.10723 14.7034 4.27846C14.7034 4.44969 14.6353 4.6139 14.5142 4.73491L13.1464 6.10265L13.1458 6.1033Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditPencilIconSVG;
