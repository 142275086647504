import { useContext, useEffect, useState } from "react";
import Switch from "components/global/switch";
import Input from "components/input/input";
import NotificationButton from "./button-type";
import RoundedButton from "components/global/button";
import { Context } from "context/context";
import Dropbox from "./dropbox";
import toast from "react-hot-toast";
import moment from "moment";
import LoadingIndicator from "assets/svg/loading-indicator";
import DatePicker from "components/datepicker/datepicker";

const NotificationBuilder = ({ record, onClose }) => {
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const { notification, setNotification } = useContext(Context);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const handleInputOnChange = (event) => {
    setNotification((prev) => ({
      ...prev,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const handleButtonChange = (event, index) => {
    setNotification((prev) => ({
      ...prev,
      buttons: prev.buttons.map((button, btnIndex) =>
        btnIndex === index
          ? {
              ...button,
              [event.target.name]: event.target.value,
            }
          : button
      ),
    }));
  };

  const handleBannerImageDrop = (file) => {
    if (!file)
      return setNotification((prev) => ({
        ...prev,
        image: undefined,
        file: undefined,
      }));

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setNotification((prev) => ({ ...prev, image: reader.result, file }));
    reader.onerror = () => toast.error("Error while uploading image");
  };

  const uploadFile = async (file) => {
    const fd = new FormData();
    fd.append("file", file);
    const { data: response } = await http.post("medias", fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response;
  };

  const createNotification = async (data) => {
    const { data: response } = await http.post("notifications", data);
    return response;
  };

  const updateNotification = async (data) => {
    const { data: response } = await http.put(`notifications/${data.id}`, data);
    return response;
  };

  const handleBuilderSubmit = async (event) => {
    try {
      event.preventDefault();
      let upload;
      const { image, file, scheduled, ...rest } = notification;

      if (notification.file) upload = await uploadFile(notification.file);

      const data = {
        ...rest,
        buttons: rest.buttons.filter((btn) => btn.label && btn.url),
        banner_image_id: upload?.data.id || record?.banner_image_id || null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      console.log("rest", rest);

      //validate date
      if (data.date && data.time) {
        const dateTime = `${data.date} ${data.time}`;
        const formattedDate = new Date(dateTime);
        const date = moment(formattedDate).valueOf();
        const currentDate = moment().valueOf();

        if (date < currentDate) {
          return toast.error(
            "Scheduled notification date and time must not less than current date and time."
          );
        }
      }

      setLoading(true);

      const response = data.id
        ? await updateNotification(data)
        : await createNotification(data);

      if (!response.success) return toast.error(response.message);

      setLoading(false);
      onClose(response);
    } catch (ex) {
      console.error(ex);
      setLoading(false);
      if (ex.status === 422)
        return toast.error("It appears that something went wrong");

      toast.error("Error while creating notification");
    }
  };

  useEffect(() => {
    if (record) {
      const buttons =
        notification.buttons?.map((obj, index) => {
          if (JSON.parse(record.buttons)[index])
            return (obj = JSON.parse(record.buttons)[index]);

          return obj;
        }) || [];

      setNotification((prev) => ({
        ...prev,
        id: record.id || null,
        type: record.type,
        image: record.banner_image?.url || null,
        title: record.title,
        message: record.message,
        scheduled: record.date && record.time,
        date: record.date,
        time: record.time,
        buttons: buttons,
      }));

      if (record.date && record.time) {
        const date = new Date(record.date);
        const dateTime = `${record.date} ${record.time}`;
        const time = new Date(dateTime);
        setDate(date);
        setTime(time);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <div className="flex flex-col h-full w-full">
      <h1 className="text-lg pl-0.5">
        {record?.id ? "Edit Notification" : "New Notification"}
      </h1>
      <form className="flex flex-col h-full" onSubmit={handleBuilderSubmit}>
        <div className="flex items-center mt-8 pl-0.5">
          <p className="input-label-upper mr-5">Type</p>
          <div>
            <NotificationButton
              type="button"
              active={notification.type === "push"}
              className="mr-3 px-8"
              onClick={() =>
                handleInputOnChange({ target: { name: "type", value: "push" } })
              }
            >
              Push Notification
            </NotificationButton>
            <NotificationButton
              type="button"
              active={notification.type === "banner"}
              className="px-8"
              onClick={() =>
                handleInputOnChange({
                  target: { name: "type", value: "banner" },
                })
              }
            >
              Banner
            </NotificationButton>
          </div>
        </div>
        {notification.type === "banner" ? (
          <Dropbox onDrop={handleBannerImageDrop} file={notification.file} />
        ) : null}

        <div className="mt-5">
          <Input
            inputContainerClass="form-input-container"
            value={notification.title}
            outsideLabel="Title"
            placeholder="Title"
            name="title"
            type="text"
            onChange={handleInputOnChange}
            required
          />
        </div>
        <div className="mb-6 mt-2 flex flex-col pl-0.5">
          <label htmlFor="message" className="mb-2">
            Message
          </label>
          <textarea
            required
            placeholder="Message"
            className="outline-none border border-gray px-5 py-3 rounded-md focus:border-red focus:outline-none"
            id="message"
            name="message"
            value={notification.message}
            onChange={handleInputOnChange}
          ></textarea>
        </div>

        <div className="flex justify-between pl-0.5">
          <p>Schedule</p>
          <div className="flex items-center">
            <p className="mr-3">Send Later</p>
            <Switch
              name="scheduled"
              checked={notification.scheduled}
              onChange={handleInputOnChange}
            ></Switch>
          </div>
        </div>

        {notification.scheduled && (
          <div className="flex w-full mt-2 space-x-1">
            <DatePicker
              label="Date"
              selected={date}
              onChange={(date) => {
                handleInputOnChange({
                  target: {
                    name: "date",
                    value: moment(date).format("YYYY-MM-DD"),
                  },
                });
                setDate(date);
              }}
              placeholderText="Select Date"
            />
            <DatePicker
              label="Times"
              selected={time}
              onChange={(date) => {
                handleInputOnChange({
                  target: { name: "time", value: moment(date).format("HH:mm") },
                });
                setTime(date);
              }}
              showTimeSelect
              showTimeSelectOnly
              placeholderText="00:00"
              timeIntervals={5}
              timeCaption="Select Time"
              dateFormat="hh:mm"
              icon="clock"
            />
          </div>
        )}

        {notification.type === "banner" ? (
          <div className="flex flex-col justify-between">
            {notification.buttons.map((button, btnIndex) => (
              <div
                key={`notif-banner-buttons-${btnIndex}`}
                className="flex flex-wrap -mx-px overflow-hidde"
              >
                <div className="my-px px-px w-full overflow-hidden md:w-5/12">
                  <Input
                    inputContainerClass="form-input-container"
                    value={button.label}
                    outsideLabel={`Button ${btnIndex + 1} Label`}
                    placeholder="e.g Click Here"
                    name="label"
                    type="text"
                    onChange={(event) => handleButtonChange(event, btnIndex)}
                  />
                </div>
                <div className="my-px px-px w-full overflow-hidden md:w-7/12">
                  <Input
                    inputContainerClass="form-input-container"
                    value={button.url}
                    outsideLabel={`Button ${btnIndex + 1} URL`}
                    placeholder="e.g https://example.com/click/here"
                    name="url"
                    type="text"
                    onChange={(event) => handleButtonChange(event, btnIndex)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="mt-auto w-full flex items-center justify-between pb-4">
          <RoundedButton
            disabled={loading}
            type="button"
            buttonType="default"
            className="w-5/12 bg-notification-button-gray text-xs text-black uppercase"
            onClick={() => onClose()}
          >
            <span>Cancel</span>
          </RoundedButton>
          <RoundedButton
            disabled={loading}
            className="w-6/12 bg-primary text-xs text-white uppercase"
            buttonType="primary"
            type="submit"
          >
            <span className="flex flex-row items-center justify-center space-x-2">
              <span>Submit</span>
              {loading && <LoadingIndicator className="text-white w-3 h-3" />}
            </span>
          </RoundedButton>
        </div>
      </form>
    </div>
  );
};

export default NotificationBuilder;
