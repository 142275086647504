const CalendarColordIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.999 20H1.99902C0.894454 20 -0.000976562 19.1046 -0.000976562 18V4C-0.000976562 2.89543 0.894454 2 1.99902 2H3.99902V0H5.99902V2H11.999V0H13.999V2H15.999C17.1036 2 17.999 2.89543 17.999 4V18C17.999 19.1046 17.1036 20 15.999 20ZM1.99902 8V18H15.999V8H1.99902ZM1.99902 4V6H15.999V4H1.99902Z"
        fill="#EC5627"
      />
    </svg>
  );
};

export default CalendarColordIcon;
