import "./styles.css";

const ActionIconBtn = ({
  className = "action-btn",
  icon = null,
  disabled = false,
  onClick = () => {},
  buttonName = "action-btn",
  ...props
}) => {
  return (
    <button
      className={className + ` ${!disabled && "hover:bg-default"}`}
      disabled={disabled}
      onClick={onClick}
      style={{ opacity: disabled ? "0.5" : "1", cursor: disabled && "default" }}
      data-cc={buttonName}
      {...props}
    >
      {icon && icon}
    </button>
  );
};

export default ActionIconBtn;
