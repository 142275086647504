import Button from "components/global/button";
import { useMemo } from "react";
import "./banner.css";

export const AlertBanner = ({ data, className, ...props }) => {
  const buttons = useMemo(
    () => data.buttons.filter((btn) => btn.label || btn.url),
    [data.buttons]
  );
  return (
    <div
      className={`notification-banner p-5 rounded bg-cover bg-center bg-no-repeat ${className}`}
      {...props}
      style={
        data.image
          ? { backgroundImage: `url(${data.image})` }
          : { backgroundColor: "#28265B" }
      }
    >
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-full h-full overflow-hidden flex-1 flex flex-col">
          <h1 className="text-2xl text-white font-medium">
            {data.title || "Announcement"}
          </h1>
          <p className="text-white text-lg font-extralight mt-5 mb-10">
            {data.message ||
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt quos beatae eum reiciendis?"}{" "}
          </p>
          <div className="my-5 mt-auto">
            {buttons.map((btn, btnIndex) => {
              const className = !btnIndex
                ? "bg-white text-primary mr-4 border-2 border-white px-8"
                : "bg-primary text-white border-2 border-white px-8";
              return (
                <Button
                  key={`banner-btn-${btnIndex}`}
                  as="link"
                  href={btn.url}
                  target="_blank"
                  className={className}
                >
                  {btn.label}
                </Button>
              );
            })}
          </div>
        </div>

        {/* {data.image ? (
          <div className="overflow-hidden hidden 2md:block">
            <img
              className="notification-banner-image object-cover object-center"
              src={data.image}
              alt={`Banner ${data.title}`}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default AlertBanner;
