import AccessIconSVG from "assets/svg/access-icon";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import TrashIconSVG from "assets/svg/trash-icon";
import ActionIconBtn from "components/button/action-btn";
import { Profile1 } from "constants/images";
import moment from "moment";
import { Fragment } from "react";

export const accountColumns = (
  showPanel = () => {},
  onDelete = () => {},
  onAccess = () => {}
) => [
  {
    title: "User",
    dataIndex: "first_name",
    nowrap: true,
    key: "full_name",
    render: (record) => {
      if (record.email) {
        return (
          <div className="w-full 1xl:flex items-center">
            <div>
              <img
                src={Profile1}
                alt="account"
                className="user-img mb-2 1xl:mr-3 1xl:mb-0"
              />
            </div>
            <div>
              <p className="text-base">
                {record.full_name || record.email?.split("@")[0]}
              </p>
              <p className="text-sm text-darkerGray">{record.email}</p>
            </div>
          </div>
        );
      } else {
        return <span>--</span>;
      }
    },
  },
  {
    title: "Business Name",
    nowrap: true,
    dataIndex: "business_name",
    key: "user_profiles.business_name",
    render: (record) => <p>{record.profile?.business_name || "--"}</p>,
  },
  {
    title: "Page URL",
    nowrap: true,
    dataIndex: "page_url",
    key: "user_profiles.page_url",
    render: (record) => <p>{record.profile?.page_url || "--"}</p>,
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "subscriptions.name",
    render: (record) => <p>{record.user_subscription?.name || "--"}</p>,
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    key: "last_login",
    render: (record) => {
      return (
        <Fragment>
          {record.last_login ? (
            <Fragment>
              <p>{moment(record.last_login).format("MMM DD, YYYY")}</p>
              <p className="opacity-60">
                {moment(record.last_login).fromNow()}
              </p>
            </Fragment>
          ) : (
            <p>--</p>
          )}
        </Fragment>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (record) => (
      <>
        <p
          className={`truncate w-16 font-bold capitalize ${
            record.status === "active" ? "text-green" : "text-red"
          }`}
        >
          {record.status}
        </p>
      </>
    ),
  },
  {
    title: "Billing",
    dataIndex: "billing",
    key: "disable_billing",
    render: (record) => (
      <>
        <p
          className={`truncate w-16 font-bold capitalize ${
            record.disable_billing ? "text-red" : "text-green"
          }`}
        >
          {record.disable_billing ? "Disabled" : "Enabled"}
        </p>
      </>
    ),
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    hideSorting: true,
    nowrap: true,
    render: (record) => (
      <>
        <ActionIconBtn
          className="action-btn-secondary"
          icon={<AccessIconSVG className="m-auto" />}
          buttonName={`access-profile-${record.id}-btn`}
          onClick={() => onAccess(record)}
          title="Login As Account"
        />
        <ActionIconBtn
          className="action-btn-secondary"
          icon={<EditPencilIconSVG className="m-auto" />}
          buttonName={`show-profile-${record.id}-btn`}
          onClick={() => showPanel(record)}
          title="Edit Account"
        />
        <ActionIconBtn
          className="action-btn-secondary"
          icon={<TrashIconSVG className="m-auto" />}
          buttonName={`delete-profile-${record.id}-btn`}
          onClick={() => onDelete(record)}
          title="Delete Account"
        />
      </>
    ),
  },
];
