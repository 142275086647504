import React from "react";
import SortIconSVG from "assets/svg/sort-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import Pagination from "react-responsive-pagination";
import _ from "lodash";

const thClass = "px-6 py-5 font-normal ";
const tdClass = "px-6 py-5 ";

const emptyFunc = () => {
  return null;
};

export default function Table({
  columns = [],
  dataSource = [],
  pagination = true,
  pages = [],
  onHandleChangeSorting = emptyFunc,
  onHandleChangePage = emptyFunc,
  perPage = 0,
  showingItems = "",
  currentPage = 1,
  loading = false,
}) {
  const handleSort = (enable, key) => {
    if (!enable) {
      onHandleChangeSorting(key);
    }
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-sm font-normal">
          <tr className="bg-white border-b border-gray">
            {(columns || []).map((value) => {
              let {
                title,
                key,
                align = "left",
                width = "",
                hideSorting = false,
              } = value;
              return (
                <th
                  key={key}
                  width={width ? width : ""}
                  className={thClass + `text-${align}`}
                >
                  <div
                    className={`flex flex-row items-center space-x-2 cursor-pointer justify-${
                      align === "center"
                        ? align
                        : align === "left"
                        ? "start"
                        : "end"
                    }`}
                    onClick={() => handleSort(hideSorting, key)}
                  >
                    <p className="opacity-70">{title}</p>
                    {!hideSorting && <SortIconSVG className="" fill="gray" />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        {_.isEmpty(dataSource) && !loading ? (
          <tbody className="text-base">
            <tr key="no-data" className="bg-white border-b border-gray">
              <td colSpan={_.size(columns)} className={tdClass + `text-center`}>
                <p className="text-black opacity-70 text-sm">No Data</p>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-base">
            {loading ? (
              <tr key="loading" className="bg-white border-b border-gray">
                <td
                  colSpan={_.size(columns)}
                  className={tdClass + `text-center`}
                >
                  <div className="flex items-center justify-center w-full">
                    <LoadingIndicator className="w-5 h-5 mr-3 text-secondary" />
                    <p className="text-black opacity-70">Loading ...</p>
                  </div>
                </td>
              </tr>
            ) : (
              dataSource.map((record, index) => {
                return (
                  <tr key={index} className="bg-white border-b border-gray">
                    {(columns || []).map((column) => {
                      let {
                        key,
                        align = "left",
                        dataIndex,
                        render,
                        nowrap = false,
                        breakAll = false,
                        width = "",
                      } = column;
                      if (render) {
                        return (
                          <td
                            key={key}
                            className={
                              tdClass +
                              `text-${align} ${nowrap && "whitespace-nowrap"} ${
                                breakAll && "break-all"
                              }`
                            }
                          >
                            {render(record)}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={key}
                            width={width ? width : ""}
                            className={
                              tdClass +
                              `text-${align} ${nowrap && "whitespace-nowrap"} ${
                                breakAll && "break-all"
                              }`
                            }
                          >
                            <p>{record[dataIndex] || "--"}</p>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        )}
      </table>

      {pagination && (
        <nav className="flex justify-between items-center p-3 bg-white">
          <div>
            <p className="text-sm opacity-60">Rows per page: {perPage}</p>
          </div>
          <div>
            <p className="text-sm opacity-60">
              {`Showing ${!loading ? showingItems : ""} items`}
            </p>
          </div>
          <Pagination
            total={_.size(pages)}
            current={currentPage}
            maxWidth={400}
            onPageChange={(page) => onHandleChangePage(page)}
            previousLabel="Previous"
            nextLabel="Next"
          />
        </nav>
      )}
    </div>
  );
}
