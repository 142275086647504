import React, { useState } from "react";
import { Context } from "context/context";
import { MegaPhone, Close } from "assets/svg/sidebar/icons";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function Alerts() {
  const { alerts, setAlerts } = React.useContext(Context);
  const [state] = useState({
    loading: false,
    verificationSent: false,
  });

  const removeAlert = (removeAlert) => {
    const updated = alerts.filter((item) => item !== removeAlert);
    setAlerts(updated);
  };

  return (
    <div className="alerts">
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert, i) => (
          <div className={"w-full alert"} key={`alert${i}`}>
            <div className={"flex items-center gap-4"}>
              <MegaPhone />
              {alert}
            </div>

            <button
              onClick={() => removeAlert(alert)}
              disabled={state.loading}
              className={"alert-button items-center justify-center flex"}
            >
              {state.loading ? (
                <LoadingIndicator className="text-white w-3 h-3" />
              ) : (
                <Close fill={"white"} />
              )}
            </button>
          </div>
        ))}
    </div>
  );
}
