import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "components/global/modal";
import Button from "components/button/button";
import Input from "components/input/input";
import Switch from "components/global/switch";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
import validator from "validator";
import { isValidHttpUrl, validateContactNumber } from "utils/utils";

export default function EditAccount({ hide, ...props }) {
  const { http } = global.services;
  const [state, setState] = useState({
    first_name: props?.first_name || "",
    last_name: props?.last_name || "",
    business_name: props?.business_name || "",
    page_url: props?.page_url || "",
    phone: props?.phone || "",
    disable_billing: props?.disable_billing || false,
    loading: false,
    validationErrors: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async () => {
    let payload = _.clone(state);
    delete payload.loading;
    delete payload.validationErrors;

    if (!_.isEmpty(state.page_url)) {
      if (!validator.isURL(state.page_url) || !isValidHttpUrl(state.page_url)) {
        return setState((prev) => ({
          ...prev,
          validationErrors: {
            ...state.validationErrors,
            page_url: "Website URL is invalid.",
          },
        }));
      }
    }

    if (!_.isEmpty(state.phone)) {
      const val = validateContactNumber(state.phone);
      if (!val.status) {
        return setState((prev) => ({
          ...prev,
          validationErrors: { ...state.validationErrors, phone: val.message },
        }));
      }
    }

    try {
      setState((prev) => ({ ...prev, loading: true }));
      const req = await http.put(`accounts/${props.id}`, payload);
      if (req.data.success) {
        let profile = req.data.data.profile;
        let user = req.data.data.user;
        delete user.id;
        toast.success(req.data.message);
        hide({ ...user, profile: profile });
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
      console.log("error?.data?.message", error?.data?.message);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end w-8/12 space-x-2">
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn"
          buttonName="SAVE CHANGES"
          buttonClass="bg-primary rounded-full py-3 px-9"
          buttonTextClass="text-white text-sm font-bold"
          onClick={handleSubmit}
          disabled={state.loading}
          buttonWidth="45%"
          icon={
            state.loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="746px"
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            Edit Account
          </h3>
          <div className="mt-4 relative">
            <div className="w-full grid grid-cols-2 gap-3 mb-6">
              <Input
                inputContainerClass="input-container"
                value={state.first_name}
                placeholder="Susie"
                outsideLabel="First Name"
                labelClass="mb-5"
                name="first_name"
                type="text"
                noLabelPaddingY="0.7rem"
                onChange={handleInputChange}
                errorMessage={state.validationErrors?.first_name}
              />
              <Input
                inputContainerClass="input-container"
                value={state.last_name}
                placeholder="Carlson"
                outsideLabel="Last Name"
                labelClass="mb-5"
                name="last_name"
                type="text"
                noLabelPaddingY="0.7rem"
                onChange={handleInputChange}
                errorMessage={state.validationErrors?.last_name}
              />
            </div>
            <div className="w-full mb-6">
              <Input
                inputContainerClass="input-container"
                value={state.business_name}
                placeholder="Business Name Here"
                outsideLabel="Business Name"
                labelClass="mb-5"
                name="business_name"
                type="text"
                noLabelPaddingY="0.7rem"
                onChange={handleInputChange}
                errorMessage={state.validationErrors?.business_name}
              />
            </div>

            <div className="w-full grid grid-cols-2 gap-3 mb-6">
              <Input
                inputContainerClass="input-container"
                value={state.page_url}
                placeholder="www.website.com"
                outsideLabel="Website URL"
                labelClass="mb-5"
                name="page_url"
                type="text"
                noLabelPaddingY="0.7rem"
                onChange={handleInputChange}
                errorMessage={state.validationErrors?.page_url}
              />
              <Input
                inputContainerClass="input-container"
                value={state.phone}
                placeholder=" 02 1234 5678"
                outsideLabel="Phone Number"
                labelClass="mb-5"
                name="phone"
                type="text"
                noLabelPaddingY="0.7rem"
                onChange={handleInputChange}
                errorMessage={state.validationErrors?.phone}
              />
            </div>

            <div className="w-full">
              <div className="flex items-center">
                <p className="mr-3">Disable Billing</p>
                <Switch
                  name="user-billing"
                  checked={state.disable_billing}
                  onChange={() =>
                    setState((prev) => ({
                      ...prev,
                      disable_billing: !state.disable_billing,
                    }))
                  }
                ></Switch>
              </div>
            </div>
          </div>
        </div>

        <span
          className="close absolute top-6 right-8"
          onClick={() => hide()}
        ></span>
      </div>
    </Modal>
  );
}
