import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Checkbox from "components/checkbox/checkbox";
import Input from "components/input/input";
import { LogoWhite } from "constants/images";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import { FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import userActions from "redux/modules/user/actions";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const Login = ({ setUserData }) => {
  const history = useHistory();
  const { http, localstorage } = global.services;
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
    loading: false,
    validationErrors: null,
  });
  const [recaptcha, setRecaptcha] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  const recaptchaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const onChangeCaptcha = (value) => {
    setRecaptcha(value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (RECAPTCHA_KEY) {
      if (!recaptcha) {
        setRecaptchaError("reCAPTCHA is required.");
        return false;
      }

      setRecaptchaError(null);

      state.recaptchaToken = recaptcha;
    }

    setState((prev) => ({ ...prev, validationErrors: null, loading: true }));

    try {
      const req = await http.post("auth/login", state);
      const userData = req.data;
      if (userData.user.role === "admin") {
        localstorage.set("user_data", JSON.stringify(userData));
        setUserData(userData);
        return history.replace("/");
      } else {
        toast.error("This site is only accessible by admin users");
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : "It appears that something went wrong"
      );
    } finally {
      recaptchaRef.current?.reset();
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, rememberMe: isChecked });
  };

  return (
    <div className="form-page-wrapper">
      <div className="form-logo-container">
        <img src={LogoWhite} alt="component-brand-logo" className="form-logo" />
      </div>

      <div className="form-container">
        <h2 className="form-column-title">Login to your account</h2>
        <div className="form-column-container">
          <form id="login-page-component" onSubmit={handleLogin}>
            <Input
              inputContainerClass="form-input-container"
              value={state.email}
              autoFocus={true}
              name="email"
              label="Email Address"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.email}
            />
            <Input
              inputContainerClass="form-input-container"
              value={state.password}
              label="Password"
              name="password"
              type={state.showPassword ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.password}
            />

            {RECAPTCHA_KEY && (
              <div className="w-full py-4 px-1">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={RECAPTCHA_KEY}
                  onChange={onChangeCaptcha}
                />
                {(recaptchaError || state.validationErrors?.recaptchaToken) && (
                  <p className="text-red text-xs mt-1">
                    {recaptchaError || state.validationErrors?.recaptchaToken}
                  </p>
                )}
              </div>
            )}

            <div className="checkbox-container">
              <Checkbox
                label={<p className="checkbox-text">Keep me logged in</p>}
                name={"termsAndConditions"}
                checked={state.terms}
                onChange={handleUpdateCheckbox}
              />
              <Link to={"/forgot-password"} className="forgot-password-link">
                Forgot password?
              </Link>
            </div>

            <Button
              buttonName="Login"
              buttonType="secondary"
              onClick={handleLogin}
              disabled={state.loading}
              type="submit"
              buttonWidth="100%"
              icon={
                <div className="absolute right-3 bottom-0 top-0 flex items-center">
                  {state.loading ? (
                    <LoadingIndicator className="text-white w-5 h-5" />
                  ) : (
                    <FaChevronRight className="text-white text-base 2md:text-lg" />
                  )}
                </div>
              }
            />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);
