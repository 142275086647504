import Layout from "layouts/layout";
import { connect } from "react-redux";
// import BellIconSVG from "assets/svg/bell-icon";
import Card from "components/global/card/card";
import CardBody from "components/global/card/card-body";
import CardFooter from "components/global/card/card-footer";
import CardHeader from "components/global/card/card-header";

const Dashboard = ({ user }) => {
  return (
    <Layout title="Dashboard" routeName="dashboard">
      <div className="main-page-wrapper">
        <div className="w-full flex flex-wrap justify-between items-center">
          <p className="text-xl">
            Hi{user?.profile?.first_name ? ` ${user?.profile?.first_name}` : ""}
            , welcome back!
          </p>
        </div>
        <div className="mt-22px">
          <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 w-full overflow-hidden">
              <Card>
                <CardHeader>
                  <p className="text-md font-medium 2md:text-xl w-full">
                    Quick Links
                  </p>
                </CardHeader>
                <CardBody className="relative">
                  <p>
                    <a
                      className="inline-block defaultButton rounded-full text-white bg-primary py-3 px-7"
                      href="https://dashboard.stripe.com/billing"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Stripe Billing Dashboard
                    </a>
                  </p>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};
export default connect(mapStateToProps)(Dashboard);
