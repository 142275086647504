import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Context } from "context/context";
import { Transition } from "@headlessui/react";
import SideBarContent from "./sidebar-content";
import userActions from "redux/modules/user/actions";
import { useHistory } from "react-router-dom";

function SideBar({ routeName, user, setUserData }) {
  const { mobileSidebar, setMobileSidebar } = React.useContext(Context);
  const { http, localstorage } = global.services;
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await http.post("auth/logout");
      setMobileSidebar(false); // Close sidebar
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      history.push("/"); // Redirect to home
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (mobileSidebar) setMobileSidebar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName]);

  return (
    <>
      <Transition
        show={mobileSidebar}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className={
          "lg:hidden z-30 max-w-screen fixed left-0 right-0 top-0 bottom-0 sidebar h-screen overflow-y-auto overflow-x-hidden xl:static text-white bg-black custom-scroll"
        }
      >
        <SideBarContent
          user={user}
          routeName={routeName}
          onLogout={handleLogout}
        />
      </Transition>
      <div className="hidden lg:block site-sidabar bg-black fixed left-0 top-0 right-0 bottom-0 sidebar h-screen overflow-y-auto overflow-x-hidden lg:static text-white custom-scroll">
        <SideBarContent
          user={user}
          routeName={routeName}
          onLogout={handleLogout}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
