import React from "react";
import { csx } from "utils/utils";

const CardBody = ({ children, className, ...props }) => {
  const classes = csx(["w-full py-5 px-10", className]);
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default CardBody;
