const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const STRIPE_DASHBOARD = process.env.REACT_APP_STRIPE_DASHBOARD;

console.log(STRIPE_DASHBOARD);

export {
  API_ENDPOINT,
  APP_PUBLIC_URL,
  GOOGLE_MAPS_API_KEY,
  PUSHER_API_KEY,
  PUSHER_CLUSTER,
  STRIPE_DASHBOARD,
};
