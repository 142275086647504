import AlertBanner from "components/alerts/banner";
import AlertNotification from "components/alerts/notification";
import { Context } from "context/context";
import { useContext } from "react";

const NotificationPreview = () => {
  const { notification } = useContext(Context);

  return (
    <div className="w-full h-full flex flex-col">
      <h1 className="text-lg">Preview</h1>
      <div className="w-full h-full flex items-center justify-center">
        {notification.type === "push" ? (
          <AlertNotification data={notification} />
        ) : (
          <AlertBanner data={{ ...notification }} />
        )}
      </div>
    </div>
  );
};

export default NotificationPreview;
