import React from "react";
import { IoClose } from "react-icons/io5";

const SearchValueCloseable = ({
  className = "pl-1.5 py-1 pr-1 bg-secondary text-white rounded-md flex justify-between items-center",
  onClick = () => {},
  value = "",
}) => {
  return (
    <div className={className}>
      <p>{value}</p>
      <button className="ml-3" onClick={onClick}>
        <IoClose className="text-xl" />
      </button>
    </div>
  );
};

export default SearchValueCloseable;
