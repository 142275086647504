const CloseIconSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.4143L7.75736 17.6569L6.34315 16.2427L10.5858 12.0001L6.34315 7.75744L7.75736 6.34323L12 10.5859L16.2426 6.34323L17.6569 7.75744L13.4142 12.0001L17.6569 16.2427L16.2426 17.6569L12 13.4143Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseIconSVG;
