const TrashIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 14.6666H4.66667C3.93029 14.6666 3.33333 14.0697 3.33333 13.3333V4.66663H2V3.3333H4.66667V2.66663C4.66667 1.93025 5.26362 1.3333 6 1.3333H10C10.7364 1.3333 11.3333 1.93025 11.3333 2.66663V3.3333H14V4.66663H12.6667V13.3333C12.6667 14.0697 12.0697 14.6666 11.3333 14.6666ZM4.66667 4.66663V13.3333H11.3333V4.66663H4.66667ZM6 2.66663V3.3333H10V2.66663H6ZM10 12H8.66667V5.99996H10V12ZM7.33333 12H6V5.99996H7.33333V12Z"
        fill="black"
      />
    </svg>
  );
};

export default TrashIconSVG;
