import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import TrashIconSVG from "assets/svg/trash-icon";
import ActionIconBtn from "components/button/action-btn";
import ConfirmModal from "components/confirm/confirm";
import Table from "components/table/table";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDialogHook } from "utils/customhooks";

const noop = () => {};

const PlansTable = ({
  showPanel = noop,
  onChangePage = noop,
  page = 1,
  drawerClose = false,
  search = "",
  selectedTab = "all",
}) => {
  const { http } = global.services;
  const [state, setState] = useState({
    data: [],
    limit: 10,
    loading: false,
    sortBy: "created_at",
    isDescending: false,
  });
  const confirm = useDialogHook(ConfirmModal);

  const onHandleChangeSorting = (sortBy) =>
    setState({ ...state, sortBy, isDescending: !state.isDescending });

  const getPlans = async (selectedTab) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const req = await http.get(`custom-subscriptions`, {
        limit: state.limit,
        page: page,
        sortBy: state.sortBy,
        orderBy: state.isDescending ? "DESC" : "ASC",
        search: search,
        type: selectedTab,
      });

      const data = req.data.data;
      setState((prev) => ({ ...prev, data }));
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const confirmDelete = (id) => {
    const payload = {
      title: "Delete Custom Plan",
      buttonText: "YES, DELETE IT",
      description: "Do you want to delete this custom plan?",
    };
    confirm(payload, (result) => {
      if (result) {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const result = await http.delete(`custom-subscriptions/${id}`);
      const data = result.data;
      if (data.success) {
        toast.success(data.message);
        getPlans(selectedTab);
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const pageCount = Math.ceil(state.data.total / state.data.per_page);
  const pages = _.range(1, pageCount + 1);

  const columns = [
    {
      title: "User",
      dataIndex: "email",
      nowrap: true,
      key: "users.email",
    },
    {
      title: "Plan",
      dataIndex: "name",
      key: "name",
      width: "350px",
    },
    {
      title: "Price",
      nowrap: true,
      dataIndex: "amount",
      key: "amount",
      render: (record) => (
        <p>
          ${(record.amount / 100).toFixed(2)} / {record.interval}
        </p>
      ),
    },
    {
      title: "Status",
      nowrap: true,
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <>
          <p
            className={`truncate w-24 font-bold capitalize ${
              record.status === "pending" ? "text-yellow" : "text-green"
            }`}
          >
            {record.status}
          </p>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <ActionIconBtn
            className="action-btn-secondary"
            icon={<EditPencilIconSVG className="m-auto" />}
            onClick={() => showPanel(record)}
          />
          <ActionIconBtn
            className="action-btn-secondary"
            icon={<TrashIconSVG className="m-auto" />}
            onClick={() => confirmDelete(record.id)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (drawerClose) {
      getPlans(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawerClose,
    page,
    state.sortBy,
    state.isDescending,
    state.limit,
    search,
  ]);

  return (
    <div className="w-full">
      <Table
        columns={columns}
        dataSource={state.data?.data}
        loading={state.loading}
        pages={pages}
        onHandleChangeSorting={(column) => onHandleChangeSorting(column)}
        onHandleChangePage={(page) => onChangePage(page)}
        perPage={state?.limit}
        showingItems={`${state.data?.from || 0}-${state.data?.to || 0} of ${
          state.data?.total || 0
        }`}
        currentPage={state.data?.current_page}
      />
    </div>
  );
};

export default PlansTable;
