const UserMinus = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#F0F0F0" />
      <path
        d="M14.4 32.4001H12C12 28.4237 15.2235 25.2001 19.2 25.2001C23.1765 25.2001 26.4 28.4237 26.4 32.4001H24C24 29.7492 21.851 27.6001 19.2 27.6001C16.549 27.6001 14.4 29.7492 14.4 32.4001ZM36 25.2001H26.4V22.8001H36V25.2001ZM19.2 24.0001C16.549 24.0001 14.4 21.8511 14.4 19.2001C14.4 16.5492 16.549 14.4001 19.2 14.4001C21.851 14.4001 24 16.5492 24 19.2001C23.9967 21.8497 21.8496 23.9968 19.2 24.0001ZM19.2 16.8001C17.8889 16.8015 16.8215 17.8548 16.8027 19.1658C16.7839 20.4767 17.8207 21.5602 19.1312 21.5991C20.4418 21.638 21.541 20.6179 21.6 19.3081V19.7881V19.2001C21.6 17.8747 20.5255 16.8001 19.2 16.8001Z"
        fill="black"
      />
    </svg>
  );
};

export default UserMinus;
