import SearchIconSVG from "assets/svg/search-icon";
import UserCheck from "assets/svg/user-check";
import UserMinus from "assets/svg/user-minus";
import UserPlus from "assets/svg/user-plus";
import DeleteAccountConfirm from "components/accounts/delete-modal-account";
import AccountsDrawer from "components/accounts/drawer";
import EditAccount from "components/accounts/edit-account";
import AccountsPanel from "components/accounts/panel";
import ActiveAccounts from "components/accounts/tab-panel/active-accounts";
import AllAccounts from "components/accounts/tab-panel/all-accounts";
import SuspendedAccounts from "components/accounts/tab-panel/suspended-accounts";
import Card from "components/card/card";
import Backdrop from "components/global/backdrop";
import { TabSelector } from "components/global/tabselector";
import Input from "components/input/input";
import Overview from "components/overview/overview";
import SearchValueCloseable from "components/search-value-closeable/search-value-closeable";
import { Context } from "context/context";
import Layout from "layouts/layout";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { useDialogHook } from "utils/customhooks";
import SuspendAccountConfirm from "../../components/accounts/suspend-modal-account";

const defaultOverview = [
  {
    label: "New Accounts Today",
    value: 0,
    icon: <UserPlus />,
  },
  {
    label: "Total Active Accounts",
    value: 0,
    icon: <UserCheck />,
  },
  {
    label: "Total Suspended Accounts",
    value: 0,
    icon: <UserMinus />,
  },
];

const Accounts = ({ user = null, setUserData }) => {
  const { localstorage, http } = global.services;
  const { active_tab } = useParams();
  const history = useHistory();
  const modal = useDialogHook(EditAccount);
  const [page, setPage] = useState(1);
  const confirm = useDialogHook(DeleteAccountConfirm);
  const suspend = useDialogHook(SuspendAccountConfirm);
  const [search, setSearch] = useState({
    initialKeyword: "",
    keyword: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useTabs(["all", "active", "suspended"]);
  const [accountsOverview, setAccountsOverview] = useState(defaultOverview);
  const { showPanel, setShowPanel, selectedUser, setSelectedUser } =
    useContext(Context);
  const [loadingAccess, setLoadingAccess] = useState(false);

  const tabs = [
    {
      tab: "all",
      name: "All Accounts",
      ListComponent: AllAccounts,
    },
    {
      tab: "active",
      name: "Active",
      ListComponent: ActiveAccounts,
    },
    {
      tab: "suspended",
      name: "Suspended",
      ListComponent: SuspendedAccounts,
    },
  ];

  const onHandleShowPanel = (data) => {
    setSelectedUser(data);
    setShowPanel(true);
  };

  const onHandleEdit = (data) => {
    modal(data, (result) => {
      if (!_.isEmpty(result)) {
        const full_name =
          (result.profile.first_name ?? "") +
          " " +
          (result.profile.last_name ?? "");
        if (user?.user?.id === result?.profile?.user_id) {
          let updateUser = {
            ...user,
            full_name,
            profile: result.profile,
          };
          setSelectedUser((prev) => ({
            ...prev,
            full_name,
            profile: result.profile,
          }));
          setUserData(updateUser);
          localstorage.set("user_data", JSON.stringify(updateUser));
        }
        setSelectedUser((prev) => ({
          ...prev,
          full_name,
          profile: result.profile,
        }));
      }
    });
  };

  const onHandleDelete = (data) => {
    confirm(data, (result) => {});
  };

  const onHandleSuspend = (data) => {
    suspend(data, (result) => {});
  };

  const handleBackdropClick = (event) => {
    if (event.currentTarget === event.target) {
      setShowPanel(false);
    }
  };

  const getAccountsOverview = async () => {
    setLoading(true);

    try {
      const req = await http.get("accounts/overview");

      const data = req.data.data;
      accountsOverview[0].value = data.new_accounts;
      accountsOverview[1].value = data.active_accounts;
      accountsOverview[2].value = data.suspended_accounts;

      setAccountsOverview(accountsOverview);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setSearch((prev) => ({
      ...prev,
      keyword: search.initialKeyword,
    }));
  };

  useEffect(() => {
    getAccountsOverview();

    if (!active_tab) history.push(`/accounts/all`);
    else setSelectedTab(active_tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setSelectedTab(tab);
      history.push(`/accounts/${tab}`);
    }
  };

  const onHandleAccess = async (data) => {
    if (loadingAccess) {
      return;
    }

    setLoadingAccess(true);

    try {
      const req = await http.post("supports", {
        user_id: data.id,
      });

      if (req.data.success) {
        window.open(req.data.link, "_blank");
      } else {
        toast.error("It appears that something went wrong");
      }
    } catch (error) {
      toast.error("It appears that something went wrong");
    } finally {
      setLoadingAccess(false);
    }
  };

  return (
    <Layout title="Accounts" routeName="accounts">
      <div className="main-page-wrapper">
        <Overview items={accountsOverview} loading={loading} />

        <div className="grid items-center md:grid-cols-2 my-8">
          <nav className="flex">
            <div className="relative">
              {tabs.map((item, index) => {
                return (
                  <TabSelector
                    key={index}
                    isActive={selectedTab === item.tab}
                    onClick={() => toggle(item.tab)}
                  >
                    {item.name}
                  </TabSelector>
                );
              })}
            </div>
          </nav>

          <div className="w-full space-y-2 1md:flex 1md:space-x-2 1md:space-y-0 1md:items-center 1md:justify-end">
            <Input
              placeholder="Search"
              noLabelPaddingY="0.8rem"
              value={search.initialKeyword}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  initialKeyword: e.target.value,
                }))
              }
              onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
              inputIcon={
                <div className="cursor-pointer" onClick={handleSearch}>
                  <SearchIconSVG />
                </div>
              }
            />
          </div>
        </div>

        <div className="mt-8">
          {!_.isEmpty(search.keyword) && (
            <div className="w-full mb-4 flex items-center flex-wrap">
              <div className="mr-4">
                <p>Searching for:</p>
              </div>
              <SearchValueCloseable
                value={search.keyword}
                onClick={() => {
                  setPage(1);
                  setSearch({ ...search, keyword: "", initialKeyword: "" });
                }}
              />
            </div>
          )}

          {tabs.map((item, index) => {
            const { ListComponent } = item;
            return (
              <TabPanel key={index} hidden={selectedTab !== item.tab}>
                <Card padding="p-0">
                  <ListComponent
                    showPanel={(data) => onHandleShowPanel(data)}
                    onChangePage={(page) => setPage(page)}
                    onDelete={(data) => onHandleDelete(data)}
                    onAccess={(data) => onHandleAccess(data)}
                    page={page}
                    search={search.keyword}
                    drawerClose={!showPanel}
                  />
                </Card>
              </TabPanel>
            );
          })}
        </div>
      </div>
      {showPanel ? (
        <Backdrop
          className="flex justify-end"
          onMouseDown={handleBackdropClick}
        >
          <AccountsPanel className="z-10">
            <AccountsDrawer
              edit={(id) => onHandleEdit(id)}
              onDelete={onHandleDelete}
              user={selectedUser}
              onSuspend={onHandleSuspend}
            />
          </AccountsPanel>
        </Backdrop>
      ) : null}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
