const AccessIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 12.2738H0C0 9.78847 2.01472 7.77375 4.5 7.77375C6.98528 7.77375 9 9.78847 9 12.2738H7.5C7.5 10.6169 6.15685 9.27375 4.5 9.27375C2.84315 9.27375 1.5 10.6169 1.5 12.2738ZM12.273 9.546L11.2125 8.4855C12.1972 7.50092 12.7504 6.16548 12.7504 4.773C12.7504 3.38052 12.1972 2.04508 11.2125 1.0605L12.273 0C14.9086 2.63596 14.9086 6.90929 12.273 9.54525V9.546ZM10.1512 7.425L9.09075 6.363C9.96811 5.48455 9.96811 4.06145 9.09075 3.183L10.1512 2.12025C11.6157 3.58471 11.6157 5.95904 10.1512 7.4235V7.425ZM4.5 7.023C2.84315 7.023 1.5 5.67985 1.5 4.023C1.5 2.36615 2.84315 1.023 4.5 1.023C6.15685 1.023 7.5 2.36615 7.5 4.023C7.5 4.81865 7.18393 5.58171 6.62132 6.14432C6.05871 6.70693 5.29565 7.023 4.5 7.023ZM4.5 2.523C3.68055 2.52383 3.01342 3.18214 3.00167 4.0015C2.98993 4.82087 3.63792 5.49803 4.45702 5.52234C5.27611 5.54665 5.96312 4.90912 6 4.0905V4.3905V4.023C6 3.19457 5.32843 2.523 4.5 2.523Z"
        fill="#EC5627"
      />
    </svg>
  );
};

export default AccessIconSVG;
