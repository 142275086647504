import React, { useEffect, useState } from "react";
import Layout from "layouts/layout";
import Button from "components/button/button";
import { useDialogHook } from "utils/customhooks";
import Input from "components/input/input";
import SearchIconSVG from "assets/svg/search-icon";
import Table from "components/table/table";
import ActionIconBtn from "components/button/action-btn";
import TrashIconSVG from "assets/svg/trash-icon";
import toast from "react-hot-toast";
import BlacklistDrawer from "components/url-blacklist/drawer";
import NotificationPanel from "components/notification/panel";
import Backdrop from "components/global/backdrop";
import _ from "lodash";
import SearchValueCloseable from "components/search-value-closeable/search-value-closeable";
import ConfirmModal from "components/confirm/confirm";

export default function URLBlackList() {
  const { http } = global.services;
  const [blacklists, setBlacklists] = useState([]);
  const [showPanel, setShowPanel] = useState(false);
  const [search, setSearch] = useState({
    initialKeyword: "",
    keyword: "",
  });
  const [state, setState] = useState({
    loading: false,
    sortBy: "created_at",
    isDescending: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [meta, setMeta] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 2,
  });
  const confirm = useDialogHook(ConfirmModal);

  const getBlacklists = async (page = 1) => {
    setState((prev) => ({ ...prev, loading: true }));
    const paramsObj = {
      search: search.keyword,
      page: page,
      limit: 10,
    };
    try {
      let req = await http.get(`blacklist`, paramsObj);
      let data = req.data.data;
      setBlacklists(data);
      setMeta(req.data.meta);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
      setState((prev) => ({ ...prev, accessLoading: false }));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleSearch = () => {
    setSearch((prev) => ({
      ...prev,
      keyword: search.initialKeyword,
    }));
  };

  const confirmDelete = (id) => {
    const payload = {
      title: "Remove URL in Blacklist",
      buttonText: "YES, UNBLOCK IT",
      description: "Do you want to unblock this url?",
    };
    confirm(payload, (result) => {
      if (result) {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      const result = await http.delete(`blacklist/${id}`);
      const data = result.data;
      if (data.success) {
        toast.success(data.message);
        getBlacklists(currentPage);
      }
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const handleBackdropClick = (event) => {
    if (event.currentTarget === event.target) {
      getBlacklists(currentPage);
      setShowPanel(false);
    }
  };

  const columns = [
    {
      title: "URL",
      dataIndex: "url",
      nowrap: true,
      key: "url",
      hideSorting: true,
    },
    {
      title: "Description",
      nowrap: true,
      dataIndex: "description",
      key: "description",
      hideSorting: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <ActionIconBtn
            onClick={() => confirmDelete(record.id)}
            className="action-btn-secondary"
            icon={<TrashIconSVG className="m-auto" />}
            // disabled={record.access_granted === 0}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getBlacklists(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, state.page, state.limit, search.keyword]);

  const pageCount = Math.ceil(meta.total / meta.per_page);
  const pages = _.range(1, pageCount + 1);

  return (
    <Layout title="URL Blacklist" routeName="url-blacklist">
      <div className="main-page-wrapper">
        {/* header */}
        <div className="grid items-center md:grid-cols-2">
          <div className="flex my-3"></div>
          {/*  */}
          <div className="w-full space-y-2 1md:flex 1md:space-x-2 1md:space-y-0 1md:items-center 1md:justify-end">
            <Input
              placeholder="Search"
              noLabelPaddingY="0.8rem"
              value={search.initialKeyword}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  initialKeyword: e.target.value,
                }))
              }
              onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
              inputIcon={
                <div className="cursor-pointer" onClick={handleSearch}>
                  <SearchIconSVG />
                </div>
              }
            />
            <Button
              buttonName="Add blacklist"
              buttonClass="relative bg-primary py-3 px-8 "
              buttonTextClass="text-white text-sm"
              onClick={() => setShowPanel(true)}
            />
          </div>
        </div>
        {/* content */}
        <div className="mt-8">
          {!_.isEmpty(search.keyword) && (
            <div className="w-full mb-4 flex items-center flex-wrap">
              <div className="mr-4">
                <p>Searching for:</p>
              </div>
              <SearchValueCloseable
                value={search.keyword}
                onClick={() => {
                  setCurrentPage(1);
                  setSearch({ ...search, keyword: "", initialKeyword: "" });
                }}
              />
            </div>
          )}
          <Table
            columns={columns}
            dataSource={blacklists}
            pagination={true}
            loading={state.loading}
            pages={pages}
            currentPage={currentPage}
            onHandleChangePage={(page) => setCurrentPage(page)}
            perPage={meta.per_page}
            showingItems={`${meta?.from || 0}-${meta?.to || 0} of ${
              meta?.total || 0
            }`}
          />
        </div>
      </div>

      {showPanel ? (
        <Backdrop
          className="flex justify-end"
          onMouseDown={handleBackdropClick}
        >
          <NotificationPanel className="bg-white z-60">
            <BlacklistDrawer
              hide={(message) => {
                if (message) {
                  toast.success(message);
                }
                getBlacklists(currentPage);
                setShowPanel(false);
              }}
            />
          </NotificationPanel>
        </Backdrop>
      ) : null}
    </Layout>
  );
}
