const NotificationButton = ({
  children,
  className,
  active = false,
  ...props
}) => {
  const classes = [
    "p-3 text-base text-black rounded-md  border",
    active
      ? "border-secondary bg-secondary bg-opacity-10"
      : "border-notification-border-gray",
    className,
  ].join(" ");
  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};

export default NotificationButton;
