import Table from "components/table/table";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { accountColumns } from "../account-columns";

const noop = () => {};

const SuspendedAccounts = ({
  showPanel = noop,
  onDelete = noop,
  onChangePage = noop,
  onAccess = noop,
  page = 1,
  search = "",
}) => {
  const location = useLocation();
  const { http } = global.services;
  const { selectedUser } = useContext(Context);
  const [state, setState] = useState({
    data: [],
    limit: 10,
    loading: false,
    sortBy: "created_at",
    isDescending: true,
  });

  const onHandleChangeSorting = (sortBy) =>
    setState({ ...state, sortBy, isDescending: !state.isDescending });

  const getAccounts = async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const req = await http.get(`accounts`, {
        type: "suspended",
        sortBy: state.sortBy,
        limit: state.limit,
        page: page,
        search: search,
        orderBy: state.isDescending ? "DESC" : "ASC",
      });

      const data = req.data.data;
      setState((prev) => ({ ...prev, data }));
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (location.pathname !== "/accounts/suspended") return;

    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    state.sortBy,
    state.isDescending,
    state.limit,
    search,
    location.pathname,
  ]);

  const pageCount = Math.ceil(state.data.total / state.data.per_page);
  const pages = _.range(1, pageCount + 1);

  useEffect(() => {
    if (!_.isEmpty(state.data)) {
      const userIndex = state.data.data.findIndex(
        (obj) => obj.id === selectedUser.id
      );
      state.data.data[userIndex] = selectedUser;
      setState((prev) => ({ ...prev, data: state.data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  return (
    <div className="w-full">
      <Table
        columns={accountColumns(showPanel, onDelete, onAccess)}
        dataSource={state.data?.data}
        loading={state.loading}
        pages={pages}
        onHandleChangeSorting={(column) => onHandleChangeSorting(column)}
        onHandleChangePage={(page) => onChangePage(page)}
        perPage={state?.limit}
        showingItems={`${state.data?.from || 0}-${state.data?.to || 0} of ${
          state.data?.total || 0
        }`}
        currentPage={page}
      />
    </div>
  );
};

export default SuspendedAccounts;
