const CloudDropboxSVG = (props) => (
  <svg
    width="38"
    height="26"
    viewBox="0 0 38 26"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M30.0833 25.6668H9.49995C4.55103 25.6697 0.428862 21.8729 0.0258561 16.9404C-0.37715 12.0079 3.07403 7.59228 7.95778 6.79185C10.2057 2.80642 14.4243 0.338992 18.9999 0.333342C21.8531 0.322557 24.6246 1.28549 26.8564 3.0631C29.0479 4.80137 30.6059 7.21297 31.2898 9.92527C35.381 10.5539 38.2973 14.228 37.981 18.3551C37.6647 22.4822 34.2225 25.669 30.0833 25.6668ZM18.9999 3.50008C15.5668 3.50414 12.4017 5.35592 10.7159 8.34668L9.97495 9.6751L8.47236 9.92052C5.22698 10.4642 2.93863 13.4027 3.20654 16.6824C3.47444 19.9621 6.20935 22.4903 9.49995 22.5001H30.0833C32.5669 22.5027 34.6332 20.5915 34.8241 18.1153C35.0151 15.6391 33.2662 13.4338 30.8116 13.0555L28.728 12.7388L28.2181 10.6932C27.1656 6.46063 23.3614 3.49211 18.9999 3.50008ZM18.9999 19.3334L12.6666 13.0001H16.7041V8.2501H21.2958V13.0001H25.3333L18.9999 19.3334Z" />
  </svg>
);
export default CloudDropboxSVG;
