import React from "react";

const Card = ({
  children,
  padding = "px-8 py-5", // default
  background = "bg-white", // default background
  className = "",
}) => {
  return (
    <div className={`${background} h-full rounded-lg ${padding} ${className}`}>
      {children}
    </div>
  );
};

export default Card;
