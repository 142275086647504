import React, { Fragment, useEffect } from "react";
import { BiAlarm } from "react-icons/bi";
import { MasterCard, Profile1, Visa } from "constants/images";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import TrashIconSVG from "assets/svg/trash-icon";
import { TabSelector } from "components/global/tabselector";
import { TabPanel, useTabs } from "react-headless-tabs";
import moment from "moment";
import numeral from "numeral";
import _ from "lodash";
import UserMinusIcon from "../../assets/svg/user-minus-icon";

export default function AccountsDrawer({
  user = {},
  edit = () => {},
  onDelete = () => {},
  onSuspend = () => {},
}) {
  const [credit, setCredit] = useTabs(["credit"]);
  const [transaction, setTransaction] = useTabs(["transaction"]);

  const getImage = (card) => {
    return card === "mastercard" ? MasterCard : Visa;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrolling when modal is opened

    return () => (document.body.style.overflow = "unset"); // Enable scrolling when modal is opened
  }, []);

  return (
    <div className="h-full w-full padding-34px overflow-y-scroll">
      {/* header */}
      <div className="flex items-center space-x-8">
        <div>
          <img src={Profile1} alt="account-avatar" className="account-avatar" />
        </div>
        {/*  */}
        <div>
          {user?.profile && (
            <p className="text-lg">{`${user.profile.first_name ?? ""} ${
              user.profile.last_name ?? ""
            }`}</p>
          )}
          <p className="text-lg opacity-70">{user.email || "--"}</p>
          <p className="text-xs opacity-50 flex items-center">
            <BiAlarm className="mr-1" />
            <p className="mt-0.5">
              {moment(user.last_login).fromNow() || "--"}
            </p>
          </p>
        </div>
        {/*  */}
        <div className="w-32 flex justify-end">
          <ActionIconBtn
            className="action-btn-secondary"
            icon={<UserMinusIcon className="m-auto" />}
            buttonName={`suspended-account-${user.id}-button`}
            onClick={() => onSuspend(user)}
          />
          <ActionIconBtn
            className="action-btn-secondary"
            icon={<EditPencilIconSVG className="m-auto" />}
            buttonName={`edit-account-${user.id}-button`}
            onClick={() => edit(user?.profile)}
          />
          <ActionIconBtn
            className="action-btn-secondary"
            icon={<TrashIconSVG className="m-auto" />}
            buttonName={`delete-account-${user.id}-button`}
            onClick={() => onDelete(user)}
            disabled={user?.role === "admin"}
          />
        </div>
      </div>
      {/* details */}
      <div className="mt-10 grid grid-cols-2 gap-y-7">
        <div>
          <p className="text-xs font-bold opacity-50">Business Name</p>
          <p>{user?.profile?.business_name || "--"}</p>
        </div>
        <div>
          <p className="text-xs font-bold opacity-50">Website</p>
          <p className="text-secondary">{user?.profile?.page_url || "--"}</p>
        </div>
        <div>
          <p className="text-xs font-bold opacity-50">Phone Number</p>
          <p>{user?.profile?.phone || "--"}</p>
        </div>
      </div>
      {/* tabs */}
      <div className="w-full mt-8">
        <nav className="w-full">
          <div className="border-b border-gray">
            <TabSelector
              isActive={credit === "credit"}
              paddingY="py-6"
              onClick={() => setCredit("credit")}
            >
              <p className="text-lg font-bold">Credit Card/s</p>
            </TabSelector>
          </div>
        </nav>
        <div className="mt-5">
          <TabPanel hidden={credit !== "credit"}>
            <div className="w-full flex space-x-6 items-center">
              {user?.default_payment_method ? (
                <>
                  <img
                    src={getImage(user?.default_payment_method?.card?.brand)}
                    alt="credit-card-avatar"
                  />
                  <div>
                    <p>{user?.default_payment_method?.billing_details?.name}</p>
                    <p className="opacity-50">{`${_.toUpper(
                      user?.default_payment_method?.card?.brand
                    )} **** ${user?.default_payment_method?.card?.last4}`}</p>
                    <p className="text-xs text-black opacity-50">
                      {`Expiration Date : ${_.padStart(
                        user?.default_payment_method?.card?.exp_month,
                        2,
                        0
                      )}/${user?.default_payment_method?.card?.exp_year}`}
                    </p>
                  </div>
                </>
              ) : (
                <p className="opacity-50">No Credit Card/s</p>
              )}
            </div>
          </TabPanel>
        </div>
      </div>
      {/* second tab */}
      <div className="w-full mt-8">
        <nav className="w-full">
          <div className="border-b border-gray">
            <TabSelector
              isActive={transaction === "transaction"}
              paddingY="py-6"
              onClick={() => setTransaction("transaction")}
            >
              <p className="text-lg font-bold">Latest Transactions</p>
            </TabSelector>
          </div>
        </nav>
        <Fragment>
          <TabPanel hidden={transaction !== "transaction"}>
            <div className="w-full">
              {_.isEmpty(user?.transactions) && (
                <p className="opacity-50 mt-4">No Transaction/s</p>
              )}
              {(user?.transactions || []).map((obj, index) => {
                const total = _.toString(obj?.total);
                const totalAmount = total && total.slice(0, total.length - 2);
                return (
                  <div
                    className="grid grid-cols-2 border-b border-gray py-5"
                    key={index}
                  >
                    {/* col 1 */}
                    <div className="text-base">
                      <p className="pl-1 font-medium">--</p>
                      <div className="flex items-center">
                        <p>--</p>
                      </div>
                    </div>
                    {/* col 2 */}
                    <div className="text-base">
                      <p className="text-status-cancelled font-semibold text-right">
                        - ${numeral(totalAmount).format("0,0.00")}
                      </p>
                      <p className="opacity-50 text-right">
                        {moment(obj?.created_at).format("DD MMM YYYY")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </TabPanel>
        </Fragment>
      </div>
    </div>
  );
}
