import React, { useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { FaChevronRight } from "react-icons/fa";
import LoadingIndicator from "assets/svg/loading-indicator";
import { LogoWhite } from "constants/images";

const ForgotPassword = () => {
  const { http } = global.services;
  const [state, setState] = useState({
    email: "",
    loading: false,
    validationErrors: null,
    success: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await http.post("auth/forgot-password", state);
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          success: true,
          email: "",
          loading: false,
        }));
      }, 250);
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));

      toast.error("It appears that something went wrong");
    }
  };

  return (
    <div className="form-page-wrapper">
      <div className="form-logo-container">
        <img src={LogoWhite} alt="component-brand-logo" className="form-logo" />
      </div>

      <div className="form-container">
        <h2 className="form-column-title-req">Request Password Reset</h2>
        {state.success ? (
          <p className="form-column-subtitle text-green">
            If the email exists in our system, a reset link has been sent.
          </p>
        ) : (
          <p className="form-column-subtitle">
            Enter your email on the form below and we'll send you a password
            reset link.
          </p>
        )}

        <div className="form-column-container">
          <Input
            inputContainerClass="form-input-container"
            value={state.email}
            defaultValue={state.email}
            autoFocus={true}
            name="email"
            label="Email Address"
            type="text"
            onChange={handleInputChange}
            errorMessage={state.validationErrors?.email}
          />

          <Button
            buttonName="Submit"
            buttonType="secondary"
            onClick={handleSubmit}
            disabled={state.loading}
            buttonWidth="100%"
            icon={
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                {state.loading ? (
                  <LoadingIndicator className="text-white w-5 h-5" />
                ) : (
                  <FaChevronRight className="text-white text-base 2md:text-lg" />
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
