const Backdrop = ({ children, className, ...props }) => (
  <div
    className={`fixed inset-0 z-50 bg-black bg-opacity-50 ${className}`}
    {...props}
  >
    {children}
  </div>
);

export default Backdrop;
