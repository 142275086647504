const Button = ({
  children,
  className,
  icon,
  as = "button",
  buttonType = "primary",
  ...props
}) => {
  const hoverEffects = () => {
    let hover = "hover:bg-primaryHover";
    if (buttonType === "default") {
      hover = "hover:bg-defaultHover";
    } else if (buttonType === "secondary") {
      hover = "hover:bg-secondaryHover";
    } else if (buttonType === "transparent") {
      hover = "hover:bg-transparent";
    } else if (buttonType === "error") {
      hover = "hover:bg-lightRedHover";
    }
    return hover;
  };

  if (as === "button") {
    return (
      <button
        className={`custom-rounded-button px-3 py-3 rounded-full duration-300 ${className} ${
          props.disabled ? "opacity-70 cursor-auto" : hoverEffects()
        }`}
        {...props}
      >
        {icon && icon}
        <p className="text-sm font-bold uppercase">{children}</p>
      </button>
    );
  }

  return (
    <a
      className={`text-sm font-bold uppercase text-center custom-rounded-button px-3 py-3 rounded-full duration-300 ${className} ${
        props.disabled ? "opacity-70 cursor-auto" : hoverEffects()
      }`}
      {...props}
    >
      {children}
    </a>
  );
};

export default Button;
