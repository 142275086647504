import _ from "lodash";

export const csx = (classes) => classes.filter(Boolean).join(" ");

export const isValidHttpUrl = (str) => {
  const url = str.replace("www.", "");
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(url);
};

export const validateContactNumber = (number) => {
  if (_.isEmpty(_.trim(number))) {
    return { message: "The Contact Number format is invalid.", status: false };
  } else {
    if (number.match(/^[0-9+]+$/)) {
      if (_.size(number) >= 6) {
        return { message: "", status: true };
      } else {
        return {
          message: "The Contact Number must be at least 6 characters.",
          status: false,
        };
      }
    } else {
      return {
        message: "The Contact Number format is invalid.",
        status: false,
      };
    }
  }
};
