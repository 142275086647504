const Switch = ({ children, ...props }) => (
  <label
    htmlFor={props.id}
    className={`flex items-center cursor-pointer justify-${props.justify}`}
  >
    <div className="relative">
      <input type="checkbox" className="sr-only" {...props} />
      <div
        className={`block w-12 h-7 rounded-full ${
          props.checked ? "bg-green" : "bg-gray"
        }`}
      ></div>
      <div className="dot absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition"></div>
    </div>
    <div className="ml-3 text-gray-700 font-medium">{children}</div>
  </label>
);

export default Switch;
