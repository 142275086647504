/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Layout from "layouts/layout";
import { useTabs } from "react-headless-tabs";
import { TabSelector } from "components/global/tabselector";
import toast from "react-hot-toast";
import Backdrop from "components/global/backdrop";
import Button from "components/button/button";
import NotificationPanel from "components/notification/panel";
import NotificationBuilder from "components/notification/builder";
import Input from "components/input/input";
import NotificationPreview from "components/notification/preview";
import { useContext } from "react";
import { Context } from "context/context";
import { useLocation } from "react-router-dom";
import NotificationTable from "components/notification/table";
import { useDialogHook } from "utils/customhooks";
import NotificationModal from "components/notification/modal";
import SearchIconSVG from "assets/svg/search-icon";
import _ from "lodash";
import SearchValueCloseable from "components/search-value-closeable/search-value-closeable";

const filters = ["all", "scheduled", "sent"];

const Notification = () => {
  const { http } = global.services;
  const modal = useDialogHook(NotificationModal);
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { setNotification } = useContext(Context);
  const [filter, setFilter] = useTabs(["all", "scheduled", "sent"]);
  const [search, setSearch] = useState({
    initialKeyword: "",
    keyword: "",
  });

  const [sort, setSort] = useState({
    column: "created_at",
    orderBy: "DESC",
  });

  const [notifications, setNotifications] = useState({
    all: [],
    metadata: {},
    data: [],
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); //for editing or deleting record

  const fetchNotifications = async ({ filter, page, sort }) => {
    const params = {
      page,
      category: "system",
      search: search.keyword,
      sortBy: sort?.column,
      orderBy: sort?.orderBy,
    };

    if (filter !== "all") params.status = filter;

    const { data: response } = await http.get("notifications", params);
    setNotifications({ data: response.data, metadata: response.meta });
  };

  const handleBackdropClick = async (event) => {
    if (event.currentTarget === event.target) {
      setShowPanel(false);

      // reset builder state
      setNotification();
      setSelectedRecord(null);
    }
  };

  const handleBuilderClose = async (data) => {
    setLoading(true);
    setShowPanel(false);
    if (!_.isEmpty(data)) {
      toast.success(data.message);
    }

    // reset builder state
    await fetchNotifications({ filter, page })
      .then(() => {
        setNotification();
        setSelectedRecord(null);
      })
      .finally(() => setLoading(false));
  };

  const fetchNotification = async (id) => {
    const { data: response } = await http.get("notifications/" + id);

    if (!response.data) return;

    response.data.buttons = JSON.parse(response.data.buttons);
    return setNotification(response.data);
  };

  const handleAction = (action, record) => {
    if (action === "edit") {
      if (record.status === "scheduled") return handleEdit(record);

      modal({ record, action }, (result) => {
        if (result === "update") handleEdit(record);
      });
    }
    if (action === "delete") {
      modal({ record, action }, (result) => {
        if (result.success) handleBuilderClose(result);
      });
    }
  };

  const handleSearch = () => {
    setSearch((prev) => ({
      ...prev,
      keyword: search.initialKeyword,
    }));
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setShowPanel(true);
  };

  const handleSort = async (column) => {
    setLoading(true);
    const params = {
      column,
      orderBy: sort.orderBy === "DESC" ? "ASC" : "DESC",
    };

    setSort(params);

    await fetchNotifications({ sort: params });
    setLoading(false);
  };

  useEffect(() => {
    if (!filter) return;
    setLoading(true);
    fetchNotifications({ filter, page })
      .catch((err) => {
        setNotifications((prev) => ({
          ...prev,
          data:
            filter === "all"
              ? prev.all
              : prev.all.filter((d) => d.status === filter),
        }));
        toast.error(err.data.message);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, search.keyword]);

  useEffect(() => {
    if (searchParams.has("preview")) {
      fetchNotification(searchParams.get("preview")).then((hasId) =>
        setShowPanel(hasId)
      );
    }
  }, [searchParams]);

  return (
    <>
      <Layout title="Notification" routeName="notification">
        <div className="main-page-wrapper">
          <nav className="flex my-3">
            {filters.map((currentFilter, index) => {
              return (
                <TabSelector
                  key={index}
                  isActive={filter === currentFilter}
                  onClick={() => setFilter(currentFilter)}
                >
                  <span className="capitalize">{currentFilter}</span>
                </TabSelector>
              );
            })}

            <div className="w-full space-y-2 1md:flex 1md:space-x-2 1md:space-y-0 1md:items-center 1md:justify-end">
              <Input
                placeholder="Search"
                noLabelPaddingY="0.8rem"
                value={search.initialKeyword}
                onChange={(e) =>
                  setSearch((prev) => ({
                    ...prev,
                    initialKeyword: e.target.value,
                  }))
                }
                onKeyDown={(e) => e.keyCode === 13 && handleSearch()}
                inputIcon={
                  <div className="cursor-pointer" onClick={handleSearch}>
                    <SearchIconSVG />
                  </div>
                }
              />
              <Button
                buttonName="+ NEW NOTIFICATION"
                buttonClass="relative bg-primary py-3 px-8 "
                buttonTextClass="text-white text-sm"
                onClick={() => setShowPanel(true)}
              />
            </div>
          </nav>

          {!_.isEmpty(search.keyword) && (
            <div className="w-full mb-4 flex items-center flex-wrap">
              <div className="mr-4">
                <p>Searching for:</p>
              </div>
              <SearchValueCloseable
                value={search.keyword}
                onClick={() => {
                  setPage(1);
                  setSearch({ ...search, keyword: "", initialKeyword: "" });
                }}
              />
            </div>
          )}

          <NotificationTable
            loading={loading}
            notifications={notifications}
            setPage={setPage}
            handleSort={handleSort}
            handleAction={handleAction}
          />
        </div>
      </Layout>
      {showPanel ? (
        <Backdrop
          className="flex justify-end"
          onMouseDown={handleBackdropClick}
        >
          <NotificationPanel className="bg-white z-60">
            <NotificationBuilder
              record={selectedRecord}
              onClose={(e) => handleBuilderClose(e)}
            />
          </NotificationPanel>
          <NotificationPanel className="bg-notification-gray z-60">
            <NotificationPreview />
          </NotificationPanel>
        </Backdrop>
      ) : null}
    </>
  );
};

export default Notification;
