import Accounts from "pages/accounts/accounts";
import CustomPlans from "pages/custom-plans/custom-plans";
import Dashboard from "pages/dashboard/dashboard";
import ForgotPassword from "pages/forgot-password/forgot-password";
import Login from "pages/login/login";
import Maintenance from "pages/maintenance/maintenance";
import NotFoundPage from "pages/not-found-page/not-found-page";
import Notification from "pages/notification/notification";
import PasswordReset from "pages/password-reset/password-reset";
import URLBlackList from "pages/url-blacklist/url-blacklist";

const routes = [
  {
    path: "/",
    authRequired: true,
    component: () => <Dashboard />,
  },
  {
    path: "/forgot-password",
    authRequired: false,
    component: () => <ForgotPassword />,
  },
  {
    path: "/password-reset",
    authRequired: false,
    component: () => <PasswordReset />,
  },
  {
    path: "/maintenance",
    authRequired: false,
    component: () => <Maintenance />,
  },
  {
    path: "/login",
    authRequired: false,
    component: () => <Login />,
  },
  {
    path: "/url-blacklist",
    authRequired: true,
    component: () => <URLBlackList />,
  },
  {
    path: "/accounts/:active_tab?",
    authRequired: true,
    component: () => <Accounts />,
  },
  {
    path: "/notification",
    authRequired: true,
    component: () => <Notification />,
  },
  {
    path: "/custom-plans/:active_tab?",
    authRequired: true,
    component: () => <CustomPlans />,
  },
  {
    path: "/login",
    authRequired: false,
    component: () => <Login />,
  },
  {
    path: "*",
    exact: true,
    component: () => <NotFoundPage />,
  },
];

export default routes;
