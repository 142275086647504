import React, { useCallback, useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";
import { Bell } from "assets/svg/sidebar/icons";
import profileImage from "assets/images/profile.png";
import mobileLogo from "assets/images/logo-colored.png";
import { Context } from "context/context";
import { usePusherEvent } from "context/pusher";
import toast from "react-hot-toast";
import { useNotifications } from "redux/modules/notification/hooks";
import NotificationList from "./notification/notification-list";
import NotificationItem from "./notification/notification-item/notification-item";
import NotificationItemMore from "./notification/notification-item-more";
import NotificationItemDefault from "./notification/notification-item/notification-item-default";
import NotificationItemSystem from "./notification/notification-item/notification-item-system";
import { useHistory } from "react-router-dom";

export default function Header({ title, userData, setUserData }) {
  const { http, localstorage } = global.services;
  const { setMobileSidebar, setShowPanel, setSelectedUser } =
    React.useContext(Context);
  const history = useHistory();
  const {
    notifications,
    hasUnreadNotification,
    addNotification,
    setNotifications,
    setNotificationUserReads,
    addNotificationUserRead,
  } = useNotifications();

  const [showNotification, setShowNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [notificationMetadata, setNotificationMetadata] = useState({
    current_page: 0,
    last_page: 0,
  });
  const canLoadMore = useMemo(
    () => notificationMetadata.current_page < notificationMetadata.last_page,
    [notificationMetadata]
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "push-notification-created-event",
    (data) => addNotification(data)
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "banner-notification-created-event",
    (data) => addNotification(data)
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "access-granted-event",
    (data) => addNotification(data)
  );

  const fetchNotifications = useCallback(
    async (page) => {
      try {
        const { data: response, status } = await http.get("notifications", {
          page,
          for: "header",
        });
        if (status === 200) {
          const nextNotifications =
            page === 1
              ? [...response.data]
              : [...notifications, ...response.data];
          setNotifications(nextNotifications);
          setNotificationMetadata(response.meta);
        }
      } catch (ex) {
        console.error(ex);
        toast.error(ex.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const fetchUserNotifications = useCallback(async () => {
    try {
      const { data: response, status } = await http.get("user-notifications");
      if (status === 200) {
        setNotificationUserReads(response.data);
      }
    } catch (ex) {
      console.error(ex);
      toast.error(ex.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationOnRead = useCallback(
    async (id) => {
      try {
        const notification = notifications.find((notif) => notif.id === id);
        if (notification.isRead) return; // return immediately so we dont need to fire another request
        const { status } = await http.post("user-notifications", {
          notification_id: id,
        });
        if (status === 200) {
          addNotificationUserRead(id);
        }
      } catch (ex) {
        console.error(ex);
        toast.error(ex.data.data.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const handleLogout = async () => {
    try {
      await http.post("auth/logout");
      setMobileSidebar(false); // Close sidebar
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      history.push(`/login`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };

  const getHandleClick = () => {
    setSelectedUser({
      ...userData.user,
      profile: userData?.profile,
      email: userData.user?.email,
      full_name: userData.user?.full_name,
      role: userData.user?.role,
    });
    setShowPanel(true);
    history.push("/accounts/all");
  };

  useEffect(() => {
    fetchNotifications();
    fetchUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header
      style={{ height: "89px" }}
      className="relative w-full bg-white flex justify-between items-center site-header"
    >
      <div className="header-left flex items-center gap-4">
        <button onClick={() => setMobileSidebar(true)} className={"lg:hidden"}>
          Menu
        </button>
        <img
          src={mobileLogo}
          alt={"ConversionCow Logo"}
          className={"w-10 lg:hidden"}
        />
        <h2 className="text-xl">{title && title}</h2>
      </div>
      <div className="header-right flex items-center justify-end gap-4">
        <Popup
          position="bottom right"
          open={showNotification}
          onClose={() => setShowNotification(false)}
          trigger={
            <button onClick={() => setShowNotification((open) => !open)}>
              <Bell show_ball={hasUnreadNotification} />
            </button>
          }
        >
          <NotificationList>
            {notifications.map((notification) => (
              <NotificationItem
                key={`notification-${notification.id}`}
                isRead={notification.isRead}
                onRead={() => handleNotificationOnRead(notification.id)}
              >
                {notification.category === "system" ? (
                  <NotificationItemSystem
                    onPreviewClick={() => setShowNotification(false)}
                    notification={notification}
                  />
                ) : (
                  <NotificationItemDefault notification={notification} />
                )}
              </NotificationItem>
            ))}
            {canLoadMore ? (
              <NotificationItemMore
                onClick={() =>
                  fetchNotifications(notificationMetadata.current_page + 1)
                }
              >
                Load more
              </NotificationItemMore>
            ) : (
              <NotificationItemMore>You caught up</NotificationItemMore>
            )}
          </NotificationList>
        </Popup>
        {/* account */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className={"profile-button"}>
            <img
              src={profileImage}
              alt="two-column-layout-component-brand-logo"
              className="w-12 2md:w-16 profile-image "
            />
          </div>
          <div className="pl-3 hidden md:flex">
            <div className="profile-name">
              <span className="profile-name-text">
                {userData?.user.full_name || userData?.user.email.split("@")[0]}
              </span>
              <br />
              <p className="font-light text-darkGray text-left">
                Administrator
              </p>
            </div>
          </div>
          {open && (
            <div className="absolute right-0 top-22 z-50 flex flex-col bg-white border rounded-md shadow-2xl border-lightGray">
              <li
                className="flex items-center px-4 py-2 pr-20 space-x-2 cursor-pointer hover:bg-lightGray"
                onClick={getHandleClick}
              >
                <span>Account Settings</span>
              </li>
              <li
                className="flex items-center px-4 py-2 pr-20 space-x-2 cursor-pointer hover:bg-lightGray"
                onClick={handleLogout}
              >
                <span>Logout</span>
              </li>
            </div>
          )}
        </div>
        {/*  */}
      </div>
    </header>
  );
}
