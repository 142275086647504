import React from "react";
import { csx } from "utils/utils";

const CardFooter = ({ children, className, ...props }) => {
  const classes = csx(["flex border-bGray items-center px-10 py-5", className]);
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default CardFooter;
