import React from "react";
import "./styles.css";

/**
 * buttonName = for button text inside button container
 * buttonWidth = custom width for button
 * buttonClass = customized class for button
 * buttonTextClass = custom classname for button text inside
 * onClick = function for clicking the button
 */

const Button = ({
  buttonName = "",
  buttonWidth,
  buttonClass = "defaultButton",
  buttonRadius = "9999px",
  buttonTextClass = "defaultButtonText",
  iconPosition = "right",
  buttonType = "primary",
  icon = null,
  loadingIcon = null,
  onClick = () => {},
  disabled = false,
}) => {
  const hoverEffects = () => {
    let hover = "hover:bg-primaryHover";
    if (buttonType === "default") {
      hover = "hover:bg-defaultHover";
    } else if (buttonType === "secondary") {
      hover = "hover:bg-secondaryHover";
    } else if (buttonType === "transparent") {
      hover = "hover:bg-transparent";
    } else if (buttonType === "error") {
      hover = "hover:bg-lightRedHover";
    }
    return hover;
  };

  return (
    <button
      className={
        buttonClass + ` duration-300 uppercase ${!disabled && hoverEffects()}`
      }
      disabled={disabled}
      onClick={onClick}
      style={
        buttonWidth
          ? {
              width: buttonWidth,
              borderRadius: buttonRadius,
              opacity: (disabled || loadingIcon) && 0.5,
              cursor: disabled && "default",
            }
          : {
              borderRadius: buttonRadius,
              opacity: (disabled || loadingIcon) && 0.5,
              cursor: disabled && "default",
            }
      }
    >
      <div className={buttonTextClass}>
        {iconPosition === "left" && icon}
        {buttonName}
        {iconPosition === "right" && icon}
        {loadingIcon && loadingIcon}
      </div>
    </button>
  );
};

export default Button;
