import NotificationBellSVG from "assets/svg/notification-bell-svg";
import "./notification.css";

const AlertNotification = ({
  className,
  data,
  onClose = () => {},
  ...props
}) => {
  return (
    <div
      className={`alert-notification flex flex-row bg-white shadow-xl rounded p-5 ${className}`}
      {...props}
    >
      <div className="flex w-full flex-col">
        <div className="flex font-bold text-black text-opacity-50 uppercase text-xs items-center w-full relative">
          <div className="mr-5">
            <NotificationBellSVG />
          </div>
          <p className="text-sm">System Notification</p>
          <span className="mx-1">&nbsp;&bull;&nbsp;</span>
          <p className="text-sm">{data.relativeTime || "Just Now"}</p>
          <p
            className="absolute right-0 text-3xl font-light cursor-pointer"
            onClick={onClose}
          >
            &times;
          </p>
        </div>
        <div className="ml-9">
          <div>
            <p className="alert-notification-title font-bold text-lg mt-2 mb-1">
              {data.title || "Push Notification"}
            </p>
            <p className="text-black text-opacity-50 text-lg">
              {data.message ||
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, repellendus."}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertNotification;
