import React from "react";

const Modal = ({
  footer = null,
  width = "510px",
  wrapperClassName = "",
  contentClassName = "",
  footerClass = "",
  responsive = false,
  ...props
}) => {
  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          aria-hidden="true"
        ></div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`${
            responsive ? width : "sm:w-full"
          } relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full ${wrapperClassName}`}
          style={!responsive ? { width: width } : null}
        >
          {/*  content here */}
          <div className={`bg-white p-4 ${contentClassName}`}>
            {props?.children}
          </div>
          {/* footer here */}
          <div
            className={`px-4 py-5 sm:px-6 sm:flex sm:flex-row-reverse ${footerClass}`}
          >
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
