import LoadingIndicator from "assets/svg/loading-indicator";
import { Close } from "assets/svg/sidebar/icons";
import RoundedButton from "components/global/button";
import Input from "components/input/input";
import Select from "components/select/select";
import { STRIPE_DASHBOARD } from "configs/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const CURRENCY = [
  { label: "AUD", value: "aud" },
  // { label: "USD", value: "usd" },
];

const INTERVAL = [
  { label: "DAILY", value: "day" },
  { label: "MONTHLY", value: "month" },
  { label: "YEARLY", value: "year" },
];

export default function CustomPlanDrawer({
  hide = () => {},
  onSubmit = () => {},
  onLoadPanel = false,
  selectedPlan = {},
  errors = {},
  setErrors = {},
}) {
  const { http } = global.services;
  const [autofillOpen, setAutofillOpen] = useState(true);
  const [state, setState] = useState({
    plan_name: "",
    amount: "",
    email: "",
    show_logo: 0,
    currency: { label: "AUD", value: "aud" }, // [aud, usd]
    interval: { label: "MONTHLY", value: "month" }, // [day, month, year]
  });
  const [emails, setEmails] = useState({
    searched: [],
    selected: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: [""] }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
    setErrors((prev) => ({ ...prev, [name]: [""] }));
  };

  const handleOnChange = (value, e) => {
    setState((prev) => ({ ...prev, [e]: value }));
  };

  const handleSelectEmail = (value) => {
    setEmails((prev) => ({ ...prev, selected: value }));
    setState((prev) => ({ ...prev, email: value.value }));
    setAutofillOpen(false);
  };

  const searchByEmail = async () => {
    try {
      const res = await http.post(`accounts/search-email`, {
        email: state.email,
      });
      return res.data.data;
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  useEffect(() => {
    if (selectedPlan != null) {
      setState({
        plan_name: selectedPlan.name,
        amount: (selectedPlan.amount / 100).toFixed(2),
        show_logo: selectedPlan.show_logo,
        email: selectedPlan.email,
        currency:
          CURRENCY[
            CURRENCY.findIndex((item) => item.value === selectedPlan.currency)
          ], //get the currency
        interval:
          INTERVAL[
            INTERVAL.findIndex((item) => item.value === selectedPlan.interval)
          ], //get the interval
      });
      setEmails((prev) => ({ ...prev, selected: selectedPlan.email }));
      setAutofillOpen(false);
    }
    const delayDebounceFn = setTimeout(async () => {
      let data = await searchByEmail();

      // replace keys
      data = data.map((item) => {
        return {
          label: item.email,
          value: item.email,
        };
      });

      // set searched data
      setEmails((prev) => ({ ...prev, searched: data }));
    }, 350);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.email]);

  return (
    <div className="flex flex-col h-full w-full overflow-y-auto mobile-scroll">
      {/* Title */}
      <div className="flex flex-col h-full w-full px-8 py-7">
        <div className="grid grid-cols-2 mb-10">
          <div>
            <h1 className="text-2xl">
              {selectedPlan ? "Edit Plan" : "Assign Plan"}
            </h1>
          </div>
          {/*  */}
          <div className="my-auto">
            <button className="float-right" onClick={hide}>
              <Close className="w-5 h-5" fill="#989898" strokeWidth={1} />
            </button>
          </div>
        </div>
        {/* content */}
        <div>
          <div className="w-full mb-5">
            <Input
              inputContainerClass="input-container"
              value={state.plan_name}
              outsideLabel="Plan Name"
              labelClass="mb-5"
              maxLength="100"
              noLabelPaddingY="0.8rem"
              placeholder="Enter Name"
              name="plan_name"
              type="text"
              onChange={handleInputChange}
              errorMessage={errors?.plan_name[0]}
              errorMessagePosition="bottom"
            />
          </div>
          <div className="w-full mb-5">
            <Select
              label="Currency"
              options={CURRENCY}
              placeholder="Select Currency"
              value={state.currency?.label}
              onChange={(e) => handleOnChange(e, "currency")}
              readOnly={!!selectedPlan} //disable update on editing
              errorMessage={errors?.currency[0]}
              errorMessagePosition="bottom"
            />
          </div>
          <div className="w-full mb-5">
            <Select
              label="Interval"
              options={INTERVAL}
              placeholder="Interval"
              value={state.interval?.label}
              onChange={(e) => handleOnChange(e, "interval")}
              readOnly={!!selectedPlan} //disable update on editing
              errorMessage={errors?.interval[0]}
              errorMessagePosition="bottom"
            />
          </div>
          <div className="w-full mb-5">
            <Input
              inputContainerClass="input-container"
              inputIconClass="left-4"
              value={state.amount}
              inputIcon={<p className="text-secondary">$</p>}
              iconPosition={"left"}
              outsideLabel="Amount"
              labelClass="mb-5"
              noLabelPaddingY="0.8rem"
              name="amount"
              type="text"
              onChange={handleInputChange}
              onKeyDown={(event) => {
                // Only allow if the e.key value is a number or if it's 'Backspace'
                if (isNaN(event.key) && event.key !== "Backspace") {
                  event.preventDefault();
                }
              }}
              disabledInput={!!selectedPlan} //disable update on editing
              errorMessage={errors?.amount[0]}
              errorMessagePosition="bottom"
            />
          </div>
          <div className="w-full mb-5">
            <Input
              inputContainerClass="input-container"
              checked={state.show_logo}
              outsideLabel={'Show "Powered by ConversionCow"'}
              labelClass="mb-5"
              noLabelPaddingY="0.8rem"
              name="show_logo"
              type="checkbox"
              onChange={handleCheckboxChange}
              errorMessage={errors?.show_logo?.[0]}
              errorMessagePosition="bottom"
              className="appearance-[unset] h-5 w-5 mt-2 rounded"
            />
          </div>
          <div className="w-full mb-5">
            <Input
              inputContainerClass="input-container"
              value={state.email}
              outsideLabel="Email Address"
              labelClass="mb-5"
              onBlur={() => setAutofillOpen(false)}
              onFocus={() => setAutofillOpen(true)}
              noLabelPaddingY="0.8rem"
              placeholder="Enter Email Address"
              name="email"
              type="text"
              onChange={(e) => {
                setAutofillOpen(true);
                handleInputChange(e);
              }}
              autofill={true}
              autofillOpen={autofillOpen}
              autofillOptions={emails.searched}
              autofillSelected={emails.selected}
              handleSelectedFill={handleSelectEmail}
              errorMessage={errors?.email[0]}
              errorMessagePosition="bottom"
              disabledInput={!!selectedPlan} //disable update on editing
            />
          </div>
        </div>
        {/* footer */}
        <div className="mt-auto w-full flex flex-row flex-wrap gap-4">
          <RoundedButton
            className="w-1/3 flex-1 bg-notification-button-gray text-black uppercase font-bold"
            disabled={onLoadPanel}
            buttonType="default"
            onClick={hide}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className="w-1/3 flex-1 bg-primary text-white uppercase relative font-bold"
            onClick={() => onSubmit(state)}
            buttonType="primary"
            disabled={onLoadPanel}
            icon={
              onLoadPanel && (
                <LoadingIndicator className="w-5 h-5 absolute right-4 top-2.5" />
              )
            }
          >
            {selectedPlan ? "Update Plan" : "Send Request"}
          </RoundedButton>
          {selectedPlan && (
            <RoundedButton
              className="w-full bg-secondary text-white uppercase font-bold"
              disabled={onLoadPanel}
              buttonType="default"
              as="link"
              href={`${STRIPE_DASHBOARD}/products/${selectedPlan.stripe_product}`}
              target="_blank"
              rel="noopener"
            >
              View in Stripe
            </RoundedButton>
          )}
        </div>
      </div>
    </div>
  );
}
