import _ from "lodash";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import userActions from "redux/modules/user/actions";
import RouteList from "./list";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}

const RouterComponent = ({ user = null, setUserData }) => {
  const { localstorage, http } = global.services;
  const [state, setState] = useState({
    renderContent: false,
  });

  useEffect(() => {
    const getUserData = async () => {
      const userData = localstorage.get("user_data");

      if (userData.value) {
        const userJson = JSON.parse(userData.value);
        try {
          //this is the one checking if token is still working
          const user = await http.get("auth/user-profile");
          let payload = _.clone(user.data);
          delete payload.profile;
          const setUser = {
            ...userJson,
            user: payload,
            profile: user.data.profile,
          };
          setUserData(setUser);
          localstorage.set("user_data", JSON.stringify(setUser));
        } catch (error) {
          console.error(error);
          localstorage.remove("user_data");
          setUserData(null);
          toast.error("It appears that something went wrong");
          return (window.location.href = `/login`);
        }
      }

      setState((prevState) => ({ ...prevState, renderContent: true }));
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localstorage, setUserData]);

  const renderRoutes = () => {
    const routes = RouteList.map((route, routeIndex) => {
      return (
        <Route
          exact
          key={`route-${routeIndex}`}
          path={route.path}
          render={() => {
            if (route.authRequired) {
              if (!user) {
                return <Redirect to={{ pathname: "/login" }} />;
              }

              // const decoded = jwt.decode(user?.access_token);
              // if (decoded) {
              //   const todayDate = Date.now();
              //   const refreshTTLDate = user?.refresh_ttl_expires * 1000;
              //   if (refreshTTLDate > todayDate) return route.component();
              //   else return <Redirect to={{ pathname: "/login" }} />;
              // }
            } else if (user) {
              // Redirect logged in users to index.
              return <Redirect to={{ pathname: "/" }} />;
            }

            return route.component();
          }}
        />
      );
    });
    return routes;
  };

  return (
    state.renderContent && (
      <React.Fragment>
        <Toaster toastOptions={{ duration: 3000 }} position="top-right" />
        <Router>
          <ScrollToTop />
          <Switch>{renderRoutes()}</Switch>
        </Router>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);
