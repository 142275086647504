import LogoLandscapeSVG from "assets/svg/logo-landscape";
import {
  Close,
  CustomPlansIcon,
  Logout,
  NotAllowed,
  Notification,
  User,
  WindowSidebar,
} from "assets/svg/sidebar/icons";
import { Context } from "context/context";
import React from "react";
import { Link } from "react-router-dom";

const linkClass = `sidebar-link items-center group w-full hover:text-white text-lighterGray flex gap-4`;
const linkClassActive = `sidebar-link items-center group w-full text-white  flex gap-4 is-active`;
const iconWrapperClass =
  "sidebar-icon-bg flex-middle rounded-md bg-lightBlack group-hover:bg-secondary";
const iconWrapperClassActive =
  "sidebar-icon-bg flex-middle rounded-md bg-secondary";
const iconClass = "group-hover:fill-primary";
const iconClassActive = "fill-primary";

export default function SideBarContent({ routeName, onLogout, user }) {
  const { setMobileSidebar } = React.useContext(Context);

  return (
    <div className="w-full">
      <div
        className={`logo-bar pt-8 lg:block justify-between text-center flex items-center w-full`}
      >
        <LogoLandscapeSVG />
        <button
          onClick={() => setMobileSidebar(false)}
          className={"lg:hidden "}
        >
          <Close className="md:h-8 md:w-8" />
        </button>
      </div>
      <div className="list-of-links">
        <Link
          className={routeName === "dashboard" ? linkClassActive : linkClass}
          to={"/"}
        >
          <div
            className={
              routeName === "dashboard"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <WindowSidebar
              className={
                routeName === "dashboard" ? iconClassActive : iconClass
              }
            />
          </div>
          Dashboard
        </Link>
        <Link
          className={
            routeName === "url-blacklist" ? linkClassActive : linkClass
          }
          to={"/url-blacklist"}
        >
          <div
            className={
              routeName === "url-blacklist"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <NotAllowed className={iconClass} />
          </div>
          URL Blacklist
        </Link>
        <Link
          className={routeName === "accounts" ? linkClassActive : linkClass}
          to={"/accounts"}
        >
          <div
            className={
              routeName === "accounts"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <User className={iconClass} />
          </div>
          Accounts
        </Link>
        <Link
          className={routeName === "notification" ? linkClassActive : linkClass}
          to={"/notification"}
        >
          <div
            className={
              routeName === "notification"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Notification className={iconClass} />
          </div>
          Notification
        </Link>
        <Link
          className={routeName === "custom-plans" ? linkClassActive : linkClass}
          to={"/custom-plans"}
        >
          <div
            className={
              routeName === "custom-plans"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <CustomPlansIcon className={iconClass} />
          </div>
          Custom Plans
        </Link>
        {user && (
          <div className={linkClass + " cursor-pointer"} onClick={onLogout}>
            <div className={iconWrapperClass}>
              <Logout className={iconClass} />
            </div>
            Logout
          </div>
        )}
      </div>
    </div>
  );
}
